import moment from "moment";
import {formatMinutes, ucFirst} from "@/utils/textFormatting";
import {useEffect} from "react";
import usePaginatedSource from "@/hooks/usePaginatedSource";
import toast from "react-hot-toast";
import PaginationControl from "@/Components/PaginationControl";

export type TransactionsTableProps = {
    transactions: any[]
    totalPages: number
    totalRecords: number
    nextPage: () => void
}

type Transaction = {
    id: string
    created_at: string
    type: string
    amount: number
    source: string
    transactionable_type: string
}


export default function () {


    const paginatedSource = usePaginatedSource<Transaction>('billing.transactions', 10);

    const {
        items,
        nextPage,
        prevPage,
        pagination,
        error,
        reloadItems
    } = paginatedSource;

    useEffect(() => {
        if (error) {
            toast.error('Failed to load transactions');
        }
    }, [error]);


    return (
        <div className="space-y-4">
            <div className="bg-white shadow rounded-lg overflow-hidden">
                <div className="flex flex-col">
                    <div className="overflow-x-auto">
                        <div className="inline-block min-w-full align-middle">
                            <table className="w-full leading-normal table-auto text-base">
                                <thead>
                                <tr>
                                    <th className="px-5 py-3 border-b bg-gray-50 border-gray-200 text-left text-sm font-bold text-gray-600 md:uppercase md:tracking-wider">
                                        Date
                                    </th>
                                    <th className="px-5 py-3 border-b bg-gray-50 border-gray-200 text-left text-sm font-semibold text-gray-600 md:uppercase md:tracking-wider">
                                        Type
                                    </th>
                                    <th className="px-5 py-3 border-b bg-gray-50 border-gray-200 text-left text-sm font-semibold text-gray-600 md:uppercase md:tracking-wider">
                                        Amount
                                    </th>
                                    <th className="px-5 py-3 border-b bg-gray-50 border-gray-200 text-left text-sm font-semibold text-gray-600 md:uppercase md:tracking-wider">
                                        Type
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {items.map((transaction, idx) => (
                                    <tr key={transaction.id}>
                                        <td className={`px-5 py-5 whitespace-nowrap text-sm ${idx < items.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                            {moment(transaction.created_at).format('MMM DD, YYYY h:mm:ss A')}
                                        </td>
                                        <td className={`px-5 py-5 text-sm ${idx < items.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                            {ucFirst(transaction.type)}
                                        </td>
                                        <td className={`px-5 py-5 text-sm ${idx < items.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                            <span
                                                className={`${transaction.amount > 0 ? 'text-green-500' : ''} font-semibold`}>
                                                {formatMinutes(transaction.amount)}
                                            </span>
                                        </td>
                                        <td className={`px-5 py-5 text-sm ${idx < items.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                            {ucFirst(transaction.type === 'debit' ?
                                                (transaction.transactionable_type?.includes('Translation') ? 'Translation' : 'Extraction') :
                                                transaction.source)
                                            }
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="border-t border-gray-200">
                        <div className="flex justify-between items-center p-4">
                        <span className="text-xs text-gray-800">
                            Page {pagination.currentPage} of {pagination.lastPage}
                        </span>
                            <PaginationControl source={paginatedSource}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
