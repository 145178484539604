import {Head} from "@inertiajs/react";
import {PageProps} from "@/types";
import TransactionsTable from "@/Pages/Billing/Partials/TransactionsTable";
import BillingPageLayout from "@/Pages/Billing/BillingPageLayout";

export default function UsagePage({auth, sessionFlash}: PageProps) {
    return (
        <>
            <Head title="Billing"/>
            <BillingPageLayout>

                <div className="">
                    <div className="flex flex-row justify-between items-center">
                        <h1 className="text-2xl">Usage</h1>
                    </div>
                    <div className="py-8">
                        <div className="max-w-7xl mx-auto space-y-6">

                            <div className="  bg-white border border-gray-200 rounded-lg shadow-sm">

                                <div className="  overflow-x-auto">

                                    <TransactionsTable/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </BillingPageLayout>
        </>
    )
}
