import Modal from "@/Components/Modal";
import React, {useMemo} from "react";
import CloseIcon from "@/Components/Icons/CloseIcon";
import {PersistedJob} from "@/types";
import moment from "moment";
import {formatMinutes, ucFirst} from "@/utils/textFormatting";

export type ExtractionJobDetailsModalProps = {
    job: PersistedJob,
    show: boolean,
    onClose: () => void,
}

export default function ({job, onClose, show}: ExtractionJobDetailsModalProps) {
    const details = useMemo(() => {
        return {
            'ID': job.job_name,
            'Status': job.status,
            'Method': ucFirst(job.method === 'ocr' ? 'vision' : job.method),
            'Processing duration': job.runtime_duration ? formatMinutes(job.runtime_duration / 60, true) : null,
            'Created': moment(job.created_at).format('YYYY-MM-DD HH:mm:ss'),
            'Subtitle Position': job.metadata?.subtitlePosition ? ucFirst(job.metadata.subtitlePosition) : null,
            'Source': job.metadata?.source ? ucFirst(job.metadata.source) : null,
        }
    }, [job]);

    return (
        <Modal maxWidth={'xl'} show={show} onClose={onClose}>
            <div id="default-modal" className="w-full">
                <div className="flex items-center p-4 md:p-7 !pb-4">
                    <div className="flex-grow flex">
                        <h3 className="text-xl font-semibold text-gray-900 flex items-center">
                            Details
                        </h3>
                    </div>
                    <button type="button"
                            onClick={onClose}
                            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 inline-flex justify-center items-center"
                    >
                        <CloseIcon/>
                        <span className="sr-only">Close modal</span>
                    </button>
                </div>
                <div className="px-6 pb-7">
                    <div className=" overflow-hidden">
                        <table className="w-full">
                            <tbody>
                            {Object.entries(details)
                                .filter(([_, val]) => val !== null)
                                .map(([key, value], index) => (
                                    <tr key={key} className={index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}>
                                        <td className="px-4 py-3 text-sm font-semibold text-gray-900">
                                            {key}
                                        </td>
                                        <td className="px-4 py-3 text-sm text-gray-600">
                                            {ucFirst(value!)}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </Modal>
    )
}
