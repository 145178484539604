import moment from "moment";

export function secondsToDuration(seconds: number): string {
    const hours = Math.floor(seconds / 3600);
    seconds -= hours * 3600;
    const minutes = Math.floor(seconds / 60);
    seconds -= minutes * 60;
    const intSeconds = Math.floor(seconds);

    return `${pad(hours, 2)}:${pad(minutes, 2)}:${pad(intSeconds, 2)}`;
}

export function ucFirst(str: string): string {
    if (!str) return str;
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

export function getLanguageName(langCode: string): string {
    try {
        if (langCode === 'und') {
            return 'Unknown';
        }
        const languageNames = new Intl.DisplayNames(['en'], {type: 'language'});
        return languageNames.of(langCode) || langCode;
    } catch (error) {
        return langCode;
    }
}

export function formatMinutes(
    minutes: number,
    includeSeconds: boolean = false
): string {
    if (minutes < 1 && includeSeconds) {
        return `${Math.round(minutes * 60)} seconds`
    }
    minutes = Math.ceil(minutes)
    if (minutes <= 60) {
        return `${minutes} minutes`;
    }
    const duration = moment.duration(minutes, 'minutes');
    const hours = Math.floor(duration.asHours());
    const mins = duration.minutes();
    if (mins === 0) {
        return `${hours} hours`;
    }
    return `${hours} hours, ${mins} minutes`;
}

export function formatCentsToDollars(cents: number) {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    }).format(cents / 100);
}

export function camelCaseToSnakeCase(str: string): string {
    return str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
}

export function bytesToSize(bytes: number) {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
    if (bytes === 0) {
        return '0 Byte'
    }
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1000)).toString())
    if (i === 0) {
        return `${bytes} ${sizes[i]}`
    }
    const val = bytes / (1000 ** i);
    if (val % 1 === 0) {
        return `${val} ${sizes[i]}`
    }
    return `${val.toFixed(1)} ${sizes[i]}`
}

function pad(num: number,
    size: number): string {
    let s = num.toString();
    while (s.length < size) {
        s = '0' + s;
    }
    return s;
}

export function replaceFileExtension(filename: string, newExtension: string): string {
    return filename.replace(/\.[^/.]+$/, '.' + newExtension);
}
