import {Head} from '@inertiajs/react';
import {PageProps} from '@/types';
import HomePageContent from "@/Pages/Home/Partials/HomePageContent";
import Main from "@/Pages/Home/Partials/Main";
import GuestLayout from "@/Layouts/Guest/GuestLayout";

export default function Home({}: PageProps) {

    return (
        <GuestLayout>
            <Head title="Welcome">
                <title>Extract hardcoded subtitles from videos online</title>
                <meta name="description"
                      content="AI-powered tool to extract hardcoded and burned-in subtitles from any video. Efficient, accurate, and supports all languages."/>
            </Head>
            <Main/>
            <div className="py-10"></div>
            <HomePageContent/>
        </GuestLayout>
    );
}
