import Modal from "@/Components/Modal";
import React, {useState} from "react";
import CloseIcon from "@/Components/Icons/CloseIcon";
import AiIcon from "@/Components/Icons/AiIcon";

export type AiTranslateInfoModalProps = {
    show: boolean;
    onClose: () => void;

};

export default function ({show, onClose}: AiTranslateInfoModalProps) {
    const [showExample, setShowExample] = useState(false);

    return (
        <Modal maxWidth={'xl'} show={show} onClose={onClose}>
            <div id="default-modal" className="w-full">
                <div className="flex items-center p-4 md:p-7">
                    <div className="w-8 h-8"></div>
                    <div className="flex-grow flex justify-center">
                        <h3 className="text-xl font-semibold text-gray-900 flex items-center">
                            <AiIcon className="h-6 w-6 text-orange-400 inline-block mr-2"/>
                            Translate with AI
                        </h3>
                    </div>
                    <button type="button"
                            onClick={onClose}
                            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 inline-flex justify-center items-center"
                    >
                        <CloseIcon/>
                        <span className="sr-only">Close modal</span>
                    </button>
                </div>
                <div className="px-7 pb-7">
                    <p className="text-base mb-8 text-gray-700">Get more accurate and fluent translations with our
                        AI-powered
                        translator. Unlike traditional translation tools like Google Translate, which process text
                        sentence by sentence, our AI models analyze the entire context of your content. This allows for
                        a deeper understanding, resulting in translations that sound natural and coherent.</p>

                    <div className="flex">
                        <div className="block w-full p-6 bg-white border border-gray-200 rounded-lg shadow">
                            <div className="justify-center">
                                <span className="font-bold text-base mb-2 inline-block">Simple Pricing</span>
                            </div>
                            <div className="bg-gray-100 p-2 rounded-lg inline-block">
                                1 Translation Minute = 0.5 Extraction Minute
                            </div>
                            <p className="mt-1 text-sm">For example, translating subtitles for a 60-minute video will
                                use 30
                                extraction minutes</p>
                        </div>
                    </div>
                </div>

            </div>
        </Modal>
    )
}
