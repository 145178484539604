export function triggerDownload(url: string, filename: string = ''): void {
    const a = document.createElement('a'); // Create <a> element
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}

export function isAudioFile(file: File): boolean {
    return file.type.startsWith('audio/') ||
        file.name.endsWith('.mp3') ||
        file.name.endsWith('.wav') ||
        file.name.endsWith('.ogg') ||
        file.name.endsWith('.flac');
}

export function downloadTextFile(content: string, filename: string): void {
    const blob = new Blob([content], {type: 'text/plain'});
    const url = URL.createObjectURL(blob);
    triggerDownload(url, filename);
    URL.revokeObjectURL(url);
}

export function sanitizeFilename(filename: string) {
    return filename.replace(/[^a-zA-Z0-9-_.]/g, '');
}

export function combineArrayBuffers(buffers: ArrayBuffer[]): ArrayBuffer {
    const totalLength = buffers.reduce((acc, value) => acc + value.byteLength, 0);
    const combinedBuffer = new ArrayBuffer(totalLength);
    let currentOffset = 0;
    const combinedView = new Uint8Array(combinedBuffer);
    buffers.forEach(buffer => {
        const view = new Uint8Array(buffer);
        combinedView.set(view, currentOffset);
        currentOffset += buffer.byteLength;
    });
    return combinedBuffer;
}
