const WS_BASE_URL = `wss://${typeof window === 'undefined' ? '' : laravelConfig('aws.job_progress_ws_url')}`
const RECONNECT_TIMEOUT = 2_000;
const WS_MAX_LIFETIME_HOURS = 2;

type SubscribeMessage = {
    action: "subscribe",
    jobId: string,
}

type SubscribeResponseMessage = {
    type: "subscribed",
    status: "success" | "error"
    jobId: string
}

type UnsubscribeMessage = {
    action: "unsubscribe",
    jobId: string,
}

type ProgressMessage = {
    type: "progress",
    progress: number,
    status?: string,
}

type ErrorMessage = {
    type: "error",
    error: string
}

type StatusMessage = {
    type: "status",
    status: string,
    [key: string]: any,
}

export type JobUpdate = ProgressMessage | StatusMessage | ErrorMessage | SubscribeResponseMessage;

type RequestProgressMessage = {
    action: "requestProgress",
    jobId: string,
}

export interface JobUpdateSubscriber {
    onMessage: (update: JobUpdate) => void,
    onError: (error: Error) => void,
}

export class JobUpdateListener {

    private socket: WebSocket | null = null;
    private socketPromise: Promise<WebSocket> | null = null;
    private subscribers: Map<string, JobUpdateSubscriber[]> = new Map<string, JobUpdateSubscriber[]>();
    private pendingSubscribe: SubscribeMessage | null = null; // subscribe via url param to save on message

    private async getSocket(): Promise<WebSocket> {
        if (this.socket) {
            return this.socket;
        }
        if (this.socketPromise) {
            return this.socketPromise;
        }
        this.socketPromise = this.createSocket();
        return this.socketPromise;
    }

    private createSocket(): Promise<WebSocket> {
        return new Promise((resolve, reject) => {
            let resolved = false;
            const url = `${WS_BASE_URL}${this.pendingSubscribe ? `?job_id=${this.pendingSubscribe.jobId}` : ''}`
            const socket = new WebSocket(url);

            socket.onopen = () => {
                resolved = true;
                this.socket = socket;
                this.pendingSubscribe = null;
                resolve(socket);
            }

            socket.onerror = (error) => {
                if (!resolved) {
                    this.socketPromise = null;
                    reject(error);
                } else {
                    this.onConnectionError(new Error(`WebSocket error: ${error}`))
                }
            }

            socket.onclose = () => {
                this.socket = null;
                this.socketPromise = null;
                if (this.subscribers.size > 0) {
                    setTimeout(
                        () => this.reconnect().catch(e => this.onConnectionError(e)),
                        RECONNECT_TIMEOUT
                    );
                }
            }

            socket.onmessage = this.onMessage.bind(this);
        });
    }

    private close() {
        this.socket?.close();
        this.socket = null;
        this.socketPromise = null;
    }

    public isClosed() {
        return !this.socket && !this.socketPromise;
    }

    private async reconnect() {
        for (const [key, subscribers] of this.subscribers) {
            if (subscribers.length > 0) {
                await this.sendSubscribeMessage(key);
            }
        }
    }

    private onConnectionError(error: Error) {
        for (const subscribers of this.subscribers.values()) {
            for (const subscriber of subscribers) {
                subscriber.onError(error);
            }
        }
    }

    private onMessage(event: MessageEvent) {
        const data = JSON.parse(event.data);
        const jobId = data.jobId;
        const subscribers = this.subscribers.get(jobId) || [];
        for (const subscriber of subscribers) {
            try {
                subscriber.onMessage(data);
            } catch (e) {
                console.error(`Failed to process message for job ${jobId} due to error: ${e}`);
            }
        }
    }

    private async sendMessage(message: Record<any, any>) {
        const client = await this.getSocket();
        client.send(JSON.stringify(message));
    }

    private async sendSubscribeMessage(jobId: string) {
        const message: SubscribeMessage = {
            action: "subscribe",
            jobId,
        }
        await this.sendMessage(message);
    }

    private async sendUnsubscribeMessage(jobId: string) {
        const message: UnsubscribeMessage = {
            action: "unsubscribe",
            jobId,
        }
        try {
            await this.sendMessage(message);
        } catch (e) {
            console.error(`Failed to unsubscribe from job ${jobId} due to error: ${e}`);
        }
    }

    public async requestProgress(jobId: string) {
        const message: RequestProgressMessage = {
            action: "requestProgress",
            jobId,
        }
        await this.sendMessage(message);
    }

    public async subscribe(jobId: string, subscriber: JobUpdateSubscriber) {
        const subscribers = this.subscribers.get(jobId) || [];
        subscribers.push(subscriber);
        this.subscribers.set(jobId, subscribers);
        if (subscribers.length > 1 && !this.isClosed()) {
            return;
        }
        if (this.isClosed()) {
            this.pendingSubscribe = {action: "subscribe", jobId};
            await this.getSocket();
        } else {
            await this.sendSubscribeMessage(jobId);
        }
    }

    public async unsubscribe(jobId: string, subscriber: JobUpdateSubscriber) {
        const subscribers = this.subscribers.get(jobId) || [];
        const index = subscribers.indexOf(subscriber);
        if (index === -1) {
            return;
        }
        subscribers.splice(index, 1);
        if (subscribers.length === 0) {
            this.subscribers.delete(jobId);
            if (this.subscribers.size > 0) {
                await this.sendUnsubscribeMessage(jobId);
            } else {
                this.close();
            }
        } else {
            this.subscribers.set(jobId, subscribers);
        }
    }

}

export default new JobUpdateListener();
