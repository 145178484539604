export default function () {
    return (
        <div className="flex items-center justify-center space-x-2 w-full">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor"
                 className="w-6 h-6 shrink-0 text-red-600 ">
                <path
                    d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z"></path>
            </svg>

            <svg className="w-5 h-5 fill-current text-blue-400" role="img" xmlns="http://www.w3.org/2000/svg"
                 viewBox="0 0 24 24">
                <path
                    d="M23.953 4.57a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.69 8.095 4.067 6.13 1.64 3.162a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.06a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.936 4.936 0 004.604 3.417 9.867 9.867 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.053 0 13.998-7.496 13.998-13.985 0-.21 0-.42-.015-.63A9.935 9.935 0 0024 4.59z"/>
            </svg>

            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="20"
                viewBox="0 0 22 20"
            >
                <g>
                    <path
                        fill="#0066DA"
                        d="M1.664 17.117l.969 1.7c.203.355.492.636.832.843l3.465-6.082H0c0 .395.102.793.3 1.149zm0 0"
                    ></path>
                    <path
                        fill="#00AC47"
                        d="M11 6.422L7.535.34a2.36 2.36 0 00-.832.844L.301 12.426c-.196.351-.301.75-.301 1.152h6.93zm0 0"
                    ></path>
                    <path
                        fill="#EA4335"
                        d="M18.535 19.66a2.36 2.36 0 00.832-.844l.403-.703 1.93-3.386c.198-.356.3-.754.3-1.149h-6.93l1.473 2.938zm0 0"
                    ></path>
                    <path
                        fill="#00832D"
                        d="M11 6.422L14.465.34a2.2 2.2 0 00-1.133-.305H8.668c-.402 0-.793.113-1.133.305zm0 0"
                    ></path>
                    <path
                        fill="#2684FC"
                        d="M15.07 13.578H6.93L3.465 19.66a2.2 2.2 0 001.133.305h12.804c.403 0 .793-.113 1.133-.305zm0 0"
                    ></path>
                    <path
                        fill="#FFBA00"
                        d="M18.496 6.805l-3.2-5.621a2.36 2.36 0 00-.831-.844L11 6.422l4.07 7.156h6.918c0-.398-.101-.793-.304-1.152zm0 0"
                    ></path>
                </g>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor"
                 className="w-6 h-6 shrink-0 text-blue-600 ">
                <path
                    d="M8.01 4.555 4.005 7.11 8.01 9.665 4.005 12.22 0 9.651l4.005-2.555L0 4.555 4.005 2 8.01 4.555Zm-4.026 8.487 4.006-2.555 4.005 2.555-4.005 2.555-4.006-2.555Zm4.026-3.39 4.005-2.556L8.01 4.555 11.995 2 16 4.555 11.995 7.11 16 9.665l-4.005 2.555L8.01 9.651Z"></path>
            </svg>

            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                 stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round"
                      d="M13.19 8.688a4.5 4.5 0 0 1 1.242 7.244l-4.5 4.5a4.5 4.5 0 0 1-6.364-6.364l1.757-1.757m13.35-.622 1.757-1.757a4.5 4.5 0 0 0-6.364-6.364l-4.5 4.5a4.5 4.5 0 0 0 1.242 7.244"/>
            </svg>


        </div>
    )
}
