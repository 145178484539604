import SrtParser, {Line} from "@qgustavor/srt-parser/src";
import {Subtitle} from "@/types";
import {downloadTextFile} from "@/utils/fileUtils";

export type SubtitleFormat = 'srt' | 'vtt' | 'txt';

interface SrtOptions {
    closeSmallGaps?: boolean;
}

interface VttOptions extends SrtOptions {
}

interface TxtOptions extends SrtOptions {
    includeTimestamps?: boolean;
    lineSpacing?: number;
}

type SubtitleOptions = SrtOptions | VttOptions | TxtOptions;

export function timestampToSeconds(timestamp: string): number {
    const parts = timestamp.split(':');
    if (parts.length !== 3) {
        throw new Error('Invalid timestamp format');
    }

    const hours = parseInt(parts[0], 10);
    const minutes = parseInt(parts[1], 10);
    const [seconds, milliseconds] = parts[2].split(',').map(val => parseInt(val, 10));

    if (isNaN(hours) || isNaN(minutes) || isNaN(seconds) || isNaN(milliseconds)) {
        throw new Error('Invalid timestamp format');
    }

    return hours * 3600 + minutes * 60 + seconds + milliseconds / 1000;
}

export const secondsToTimestamp = (seconds: number): string => {
    return SrtParser.prototype.secondsToTimestamp(seconds * 1000);
};

export function parseSrt(content: string): Subtitle[] {
    const parser = new SrtParser();
    const parsed = parser.fromSrt(content);
    return parsed.map((item: Line, index: number) => {
        return {
            id: item.id,
            startTime: item.startTime as string,
            endTime: item.endTime as string,
            startTimeSeconds: timestampToSeconds(item.startTime as string),
            endTimeSeconds: timestampToSeconds(item.endTime as string),
            text: item.text
        }
    })
}

export function subtitlesToSrt(subtitles: Subtitle[], options: SrtOptions = {}): string {
    return subtitles
        .filter(el => !el.deleted)
        .sort((a, b) => a.startTimeSeconds - b.startTimeSeconds)
        .map((subtitle, idx) => {
            const id = idx + 1;
            const text = subtitle.text.split('\n')
                .map((line: string) => line.trim())
                .filter((line: string) => line.length > 0)
                .join('\n');
            return `${id}\n${subtitle.startTime} --> ${subtitle.endTime}\n${text}\n\n`
        }).join('').trim();
}

export function subtitlesToTxt(subtitles: Subtitle[], options: TxtOptions = {}): string {
    const {includeTimestamps = false, lineSpacing = 2} = options;

    return subtitles
        .filter(el => !el.deleted)
        .sort((a, b) => a.startTimeSeconds - b.startTimeSeconds)
        .map((subtitle) => {
            const timestamp = includeTimestamps
                ? `[${subtitle.startTime} --> ${subtitle.endTime}]\n`
                : '';
            return `${timestamp}${subtitle.text}${'\n'.repeat(lineSpacing)}`;
        })
        .join('')
        .trim();
}

export function subtitlesToVtt(subtitles: Subtitle[], options: SrtOptions = {}): string {
    return `WEBVTT\n\n${subtitles.filter(el => !el.deleted)
        .sort((a, b) => a.startTimeSeconds - b.startTimeSeconds)
        .map((subtitle, idx) => {
            const startTime = subtitle.startTime.toString().replace(',', '.');
            const endTime = subtitle.endTime.toString().replace(',', '.');
            return `${idx + 1}\n${startTime} --> ${endTime}\n${subtitle.text}\n\n`
        }).join('')}.trim()`;
}


export function closeSmallGaps(subtitles: Subtitle[], maxGapSeconds: number): Subtitle[] {
    if (subtitles.length <= 1) {
        return [...subtitles];
    }

    const sortedSubtitles = [...subtitles]
        .filter(sub => !sub.deleted)
        .sort((a, b) => a.startTimeSeconds - b.startTimeSeconds);

    return sortedSubtitles.map((sub, idx) => {
        const current = sortedSubtitles[idx];
        if (idx === sortedSubtitles.length - 1) {
            return current;
        }
        const next = sortedSubtitles[idx + 1];

        const gap = next.startTimeSeconds - current.endTimeSeconds;

        if (gap > 0 && gap < maxGapSeconds) {
            return {
                ...current,
                endTimeSeconds: next.startTimeSeconds,
                endTime: secondsToTimestamp(next.startTimeSeconds)
            }
        } else {
            return current;
        }
    })
}

export function downloadSubtitles(
    format: SubtitleFormat,
    subtitles: Subtitle[],
    fileName: string,
    formatOptions: SubtitleOptions = {}
) {
    let content;
    if (formatOptions.closeSmallGaps) {
        subtitles = closeSmallGaps(subtitles, 1);
    }
    if (format === 'txt') {
        content = subtitlesToTxt(subtitles, formatOptions as TxtOptions);
    } else if (format === 'vtt') {
        content = subtitlesToVtt(subtitles, formatOptions as SrtOptions);
    } else {
        content = subtitlesToSrt(subtitles, formatOptions as SrtOptions);
    }
    downloadTextFile(content, fileName);
}
