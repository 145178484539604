import ProgressSmoother from "@/utils/ProgressSmoother";
import jobUpdateListener, {JobUpdateSubscriber} from "@/services/JobUpdateListener";

type CancelListener = () => void;

export async function initProgressListener(
    jobId: string,
    onProgress: (progress: number) => void,
    onComplete: (result: any) => void,
    onError: (error: Error) => void,
    progressSmoother?: ProgressSmoother,
    updateInterval: number = 500
): Promise<CancelListener> {
    let cancelUpdates: (() => void) | null = null;

    if (progressSmoother) {
        cancelUpdates = progressSmoother.subscribeToUpdates(onProgress, updateInterval);
    }

    const terminate = () => {
        jobUpdateListener.unsubscribe(jobId, subscriber);
        cancelUpdates?.();
    }

    const subscriber: JobUpdateSubscriber = {
        onMessage(message) {
            switch (message.type) {
                case "progress":
                    const value = Number(message.progress);
                    if (progressSmoother) {
                        progressSmoother.setValue(value);
                    } else {
                        onProgress(value);
                    }
                    break;
                case "error":
                    onError(new Error(message.error));
                    terminate();
                    break;
                case "status":
                    if (message.status === 'success' || message.status === 'completed') {
                        onComplete(message);
                        terminate();
                    }
                    break;
            }
        },
        onError(error: Error) {
            console.error(error);
        }
    }

    await jobUpdateListener.subscribe(jobId, subscriber)
    return terminate;

}
