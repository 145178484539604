import {Head, router} from "@inertiajs/react";
import {PageProps} from "@/types";
import axios from "axios";
import toast from "react-hot-toast";
import BillingPageLayout from "@/Pages/Billing/BillingPageLayout";
import PurchaseModal from "@/Pages/Dashboard/Purchase/PurchaseModal";
import {useState} from "react";
import PrimaryButton from "@/Components/PrimaryButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilm} from "@fortawesome/free-solid-svg-icons";
import {formatMinutes} from "@/utils/textFormatting";
import PaymentsTable from "@/Pages/Billing/Partials/PaymentsTable";

type BillingProps = {
    pendingPayments: any[],
    creditBalance: number,
    pricing: Record<string, number>
}

export default function CreditsPage({
    auth,
    pendingPayments,
    creditBalance,
    pricing,
    sessionFlash
}: PageProps<BillingProps>) {
    const [showPurchaseModal, setShowPurchaseModal] = useState(false);
    const cancelPayment = (paymentId: string) => {
        axios.put(route('payment.cancel-pending', paymentId))
            .then(() => {
                toast.success('Payment cancelled');
                router.reload({preserveScroll: true})
            }).catch(err => {
            const message = err.response?.data?.message || 'An error occurred while cancelling the payment';
            toast.error(message);
        });
    }

    return (
        <>
            <Head title="Billing"/>
            <BillingPageLayout>
                <PurchaseModal
                    show={showPurchaseModal}
                    onClose={() => setShowPurchaseModal(false)}
                    prices={pricing}
                />
                <div className="">
                    <div className="flex flex-row justify-between items-center mb-4">
                        <h1 className="text-2xl">Credits</h1>
                    </div>
                    <div className="flex flex-row justify-between items-center">
                        <div className="">
                            <p className="text-sm mb-0 text-gray-600">Extraction time remaining</p>
                            <div className="flex items-center mt-1">
                                <div className="mr-2 md:hidden xl:inline-block">
                                    <FontAwesomeIcon className="text-blue-600" icon={faFilm}/>
                                </div>
                                <h4 className="text-sm xl:text-base font-bold">{formatMinutes(creditBalance)}</h4>
                            </div>
                        </div>
                        <PrimaryButton className="self-center" onClick={() => setShowPurchaseModal(true)}>Purchase
                            <span className="hidden md:inline"> Credits</span></PrimaryButton>
                    </div>
                    <div className="py-8">
                        <div className="max-w-7xl mx-auto space-y-6">
                            <PaymentsTable cancelPayment={cancelPayment}/>


                        </div>
                    </div>
                </div>
            </BillingPageLayout>
        </>
    )
}
