import {combineArrayBuffers} from "@/utils/fileUtils";
import FFmpeg from "@/services/video/FFmpeg";

const NUM_WORKERS = import.meta.env.SSR ? 0 : 2;
const MIN_SPLIT_DURATION = 45 * 60;

export class AudioExtractor {

    private ffmpegs: FFmpeg[] = Array.from({length: NUM_WORKERS}, () => new FFmpeg());
    private loaded = false;
    private isRunning = false;

    constructor(private loggingEnabled = false) {
    }

    public running() {
        return this.isRunning;
    }

    private segment(duration: number, chunkDurationSize: number) {
        let index = 0;
        let durationLeft = duration;
        let chunkStart = 0;
        const chunks = [];
        while (chunkStart < duration) {
            let chunkDuration = durationLeft > chunkDurationSize ? chunkDurationSize : durationLeft;
            chunks.push({
                id: index,
                start: chunkStart,
                duration: chunkDuration
            });
            chunkStart += chunkDuration;
            index++;
        }
        return chunks;
    }

    private async chunk(inputFile: string) {
        const {duration} = await this.ffmpegs[0].getVideoMetaData(inputFile);
        if (!duration) {
            throw new Error('Invalid video file');
        }
        if (duration < MIN_SPLIT_DURATION) {
            return [{id: 0, start: 0, duration}]; // startup cost of FFMPEG is too high for small files
        }
        const halfPoint = Math.ceil(duration / 2);
        return this.segment(duration, halfPoint);
    }

    public async extractAudioAsMp3(
        file: File,
        signal: AbortSignal,
        onProgress: (progress: number) => void
    ): Promise<ArrayBuffer> {
        if (this.isRunning) {
            throw new Error('An extraction is already in progress.');
        }
        const inputDir = '/input';
        const inputFile = `${inputDir}/${file.name}`;
        const results: ArrayBuffer[] = [];
        this.isRunning = true;
        await this.waitForLoad();

        await Promise.all(this.ffmpegs.map(async (ffmpeg, idx) => {
            await ffmpeg.createDir(inputDir);
            // @ts-ignore
            await ffmpeg.mount('WORKERFS', {files: [file]}, inputDir);
        }))

        const abortHandler = () => {
            this.ffmpegs.forEach(ffmpeg => ffmpeg.terminate());
            this.loaded = false;
        };
        signal.addEventListener('abort', abortHandler);
        const chunks = await this.chunk(inputFile);
        let progress = Array.from({length: chunks.length}, () => 0);
        const onLog = (e: any) => this.loggingEnabled && console.log(e.message);

        const promises = Promise.all(this.ffmpegs.map(async (ffmpeg) => {
            let chunk: any;
            const progressHandler = (e: any) => {
                if (!signal.aborted && e.progress <= 1) {
                    progress[chunk.id] = Math.min(1, e.progress * progress.length);
                    onProgress(progress.reduce((acc, val) => acc + (val / progress.length), 0));
                }
            };
            ffmpeg.on('progress', progressHandler);
            ffmpeg.on('log', onLog);

            while (chunks.length) {
                chunk = chunks.shift()!;
                const outputFile = `/output.${chunk.id}.mp3`;
                const command = [
                    "-i", inputFile,
                    "-vn",
                    "-ac", "1",
                    "-ab", "128k",
                    "-ss", `${chunk.start}`,
                    "-t", `${chunk.duration}`,
                    "-f", "mp3",
                    outputFile
                ]
                await ffmpeg.exec(command);
                const data = await ffmpeg.readFile(outputFile);
                results[chunk.id] = (data as Uint8Array).buffer;
                try {
                    await ffmpeg.deleteFile(outputFile)
                } catch {
                    console.error('Failed to delete file', outputFile);
                }
            }
            await ffmpeg.unmount(inputDir);
            await ffmpeg.deleteDir(inputDir);

            ffmpeg.off('progress', progressHandler);
            ffmpeg.off('log', onLog);
        }))

        try {
            await promises;
            return combineArrayBuffers(results);
        } catch (e: any) {
            if (e.message?.includes('called FFmpeg.terminate')) {
                throw new DOMException('The operation was aborted.', 'AbortError');
            }
            this.ffmpegs.forEach(ffmpeg => ffmpeg.terminate());
            this.loaded = false;
            throw e;
        } finally {
            signal.removeEventListener('abort', abortHandler);
            this.isRunning = false;
        }
    }

    public async waitForLoad() {
        if (!this.loaded) {
            await this.load();
            this.loaded = true;
        }
    }

    private async load() {
        await Promise.all(this.ffmpegs.map((ffmpeg) => ffmpeg.load()));
    }
}

export default new AudioExtractor();
