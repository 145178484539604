import {PaginatedSource} from "@/hooks/usePaginatedSource";

type PaginationControlProps = {
    source: PaginatedSource<any>
    onPageChange?: () => void
}

export default function PaginationControl({source, onPageChange}: PaginationControlProps) {
    const {pagination, nextPage, prevPage} = source;

    const onNavigate = (isNext: boolean) => {
        if (isNext) {
            nextPage();
        } else {
            prevPage();
        }
        onPageChange?.();
    }

    return (
        pagination.lastPage > 1 && (
            <div className="grow flex justify-end">
                <div className="flex">
                    <button
                        onClick={() => onNavigate(false)}
                        disabled={pagination.currentPage === 1}
                        className={`flex items-center justify-center px-3 h-8 me-3 text-sm font-medium text-gray-500 border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700 bg-white ${pagination.currentPage === 1 ? 'cursor-not-allowed' : ''}`}>
                        <svg className="w-3.5 h-3.5 me-2 rtl:rotate-180" aria-hidden="true"
                             xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M13 5H1m0 0 4 4M1 5l4-4"/>
                        </svg>
                        <span>Previous</span>
                    </button>
                    <button
                        onClick={() => onNavigate(true)}
                        disabled={pagination.currentPage === pagination.lastPage}
                        className={`flex items-center justify-center px-3 h-8 me-3 text-sm font-medium text-gray-500 border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700 bg-white ${pagination.currentPage === pagination.lastPage ? 'cursor-not-allowed' : ''}`}>
                        <span>Next</span>
                        <svg className="w-3.5 h-3.5 ms-2 rtl:rotate-180" aria-hidden="true"
                             xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M1 5h12m0 0L9 1m4 4L9 9"/>
                        </svg>
                    </button>
                </div>
            </div>
        )
    )
}
