export enum ExtractionMethod {
    OCR = "ocr",
    AUDIO = "audio",
    MANUAL = "manual"
}

export enum TranslationMethod {
    GOOGLE = "google",
    AI = "ai_translator"
}

export enum PaymentStatus {
    PENDING = "pending",
    COMPLETED = "completed",
    CANCELED = "canceled",
}
