import Evaporate from "evaporate";
import {md5 as MD5} from 'js-md5';
import {sha256 as SHA256} from 'js-sha256';
import {sanitizeFilename} from "@/utils/fileUtils";

const md5 = (x: string) => {
    if (!x) return '';
    const o = MD5.create();
    o.update(x);
    return o.base64();
};
const sha256 = (x: string) => {
    const o = SHA256.create();
    o.update(x);
    return o.hex();
};

export type UploadParams = {
    file: File,
    name: string,
    awsBucket: string,
    awsKey: string,
    awsRegion: string,
    progressCallback: (percentage: number, secondsLeft?: number) => void,
    signal: AbortSignal,
    signerUrl: string,
    partSize: number,
    maxFileSize: number,
    maxConcurrentParts?: number,
    logging?: boolean,
}

export default function uploadFile({
    file,
    name,
    awsBucket,
    awsKey,
    awsRegion,
    progressCallback,
    signal,
    signerUrl,
    partSize,
    maxFileSize,
    logging,
    maxConcurrentParts = 6,
}: UploadParams) {
    let isComplete = false;
    const evaporate = new Evaporate({
        signerUrl,
        bucket: awsBucket,
        awsRegion: awsRegion,
        aws_key: awsKey,
        maxConcurrentParts: maxConcurrentParts,
        logging: logging,
        sendCanonicalRequestToSignerUrl: true,
        computeContentMd5: true,
        // @ts-ignore
        cryptoMd5Method: (data) => md5(data),
        // @ts-ignore
        cryptoHexEncodedHash256: (data) => sha256(data),
        maxFileSize: maxFileSize,
        mockLocalStorage: true,
        partSize: partSize,
        progressIntervalMS: 300,
        signHeaders: {
            'Content-Type': 'video/mp4',
            'Accept': 'application/json'
        },
        signParams: {
            fileSize: file.size,
            id: name
        },
        // @ts-ignore
        maxUploadInitAttempts: 3,
    });

    signal.addEventListener('abort', () => {
        if (!isComplete) {
            evaporate.cancel().catch(err => {
            })
        }
    })

    return new Promise<string>((resolve,
        reject) => {
        evaporate.add({
            name: name,
            file: file,
            xAmzHeadersAtInitiate: {
                'x-amz-meta-file-name': sanitizeFilename(file.name)
            },
            contentType: file.type,
            progress: (progress, stats) => {
                if (!signal.aborted) {
                    progressCallback(progress, stats.secondsLeft > 0 ? stats.secondsLeft : undefined)
                }
            },
            complete: (xhr,
                awsKey) => {
                isComplete = true;
                resolve(awsKey)
            },
            error: (msg) => {
                reject(msg)
            }
        }).catch(err => {
            if (!signal.aborted) {
                console.log('Error uploading file', err)
            }
            evaporate.cancel().catch(err => {
            })
            reject(err)
        })
    })
}

