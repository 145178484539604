import React from "react";
import {usePage} from "@inertiajs/react";
import {PageProps} from "@/types";
import AuthenticatedLayout from '@/Layouts/Auth/AuthenticatedLayout';
import GuestLayout from "@/Layouts/Guest/GuestLayout";

type PageLayoutProps = {
    header?: React.ReactNode | string | null | ((authenticated: boolean) => React.ReactNode)
    children: React.ReactNode | ((authenticated: boolean) => React.ReactNode)
}

export default function ({children, header}: PageLayoutProps) {
    const {auth} = usePage<PageProps>().props
    const authenticated = !!auth.user

    return (
        <div>
            {
                authenticated ?
                    (
                        <AuthenticatedLayout
                            header={
                                typeof header === 'string' ?
                                    <h1 className="font-semibold text-xl text-gray-800 leading-tight">{header}</h1>
                                    : (typeof header === 'function' ? header(authenticated) : header)}
                        >
                            {typeof children === 'function' ? children(authenticated) : children}
                        </AuthenticatedLayout>
                    )
                    :
                    (
                        <GuestLayout>
                            {
                                typeof header === 'string' ?
                                    <div className="container mx-auto py-10 px-4 sm:px-6 lg:px-8">
                                        <h1 className="text-3xl font-bold mb-4 mt-4">{header}</h1>
                                    </div>
                                    :
                                    (typeof header === 'function' ? header(authenticated) : header)
                            }
                            {typeof children === 'function' ? children(authenticated) : children}
                        </GuestLayout>
                    )
            }
        </div>
    );
}
