import AuthenticatedLayout from '@/Layouts/Auth/AuthenticatedLayout';
import {Head} from '@inertiajs/react';
import {PageProps, UserSettings} from '@/types';
import UpdateSettingsForm from "@/Pages/Settings/Partials/UpdateSettingsForm";

export default function Edit({settings}: PageProps<{
    settings: UserSettings
}>) {
    return (
        <AuthenticatedLayout
            header={<h2 className="font-semibold text-xl text-gray-800 leading-tight">Settings</h2>}
        >
            <Head title="Settings"/>

            <div className="py-12 mx-4 md:mx-0">
                <div className="max-w-7xl mx-auto sm:px-6 lg:px-8 space-y-6">
                    <div className="p-4 sm:p-8 bg-white shadow rounded-lg">
                        <UpdateSettingsForm settings={settings}/>
                    </div>

                </div>
            </div>
        </AuthenticatedLayout>
    );
}
