import AuthenticatedLayout from "@/Layouts/Auth/AuthenticatedLayout";
import {PageProps, PersistedJob, Subtitle} from "@/types";
import {Head, router} from "@inertiajs/react";
import {faMessage, faPenToSquare as faPen, faTrashCan} from "@fortawesome/free-regular-svg-icons";
import {
    faArrowLeft,
    faDownload,
    faEllipsisV,
    faPlus,
    faRotate,
    faRotateLeft,
    faRotateRight,
    faSearch
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useEffect, useMemo, useState} from "react";
import PrimaryButton from "@/Components/PrimaryButton";
import SecondaryButton from "@/Components/SecondaryButton";
import MediaFileInput from "@/Components/MediaFileInput";
import {downloadSubtitles, parseSrt, SubtitleFormat, subtitlesToSrt, subtitlesToVtt} from "@/utils/subtitleUtils";
import SubtitleList from "@/Pages/Subtitle/Partials/SubtitleList";
import TranslateModal from "@/Pages/Dashboard/Translate/TranslateModal";
import SearchModal from "@/Pages/Subtitle/Partials/SearchModal";
import {secondsToDuration, ucFirst} from "@/utils/textFormatting";
import moment, {Moment} from "moment";
import {Tooltip} from "flowbite-react";
import EditNameModal from "@/Pages/Subtitle/Partials/EditNameModal";
import axios from "axios";
import useHistory from "@/hooks/useHistory";
import Dropdown from "@/Components/Dropdown";
import {useDebouncedCallback} from 'use-debounce';
import toast from "react-hot-toast";
import Popover from "@/Components/Popover";
import {deleteJob} from "@/services/extractionJobManager";
import {TranslationMethod} from "@/types/enums";
import FeedbackModal from "@/Pages/Dashboard/Feedback/FeedbackModal";
import TranslateIcon from "@/Components/Icons/TranslateIcon";
import SrtParser from "@qgustavor/srt-parser/src";
import ClientRender from "@/Components/ClientOnly";
import ExtractionJobDetailsModal from "@/Pages/Subtitle/Partials/ExtractionJobDetailsModal";
import DownloadModal from "@/Pages/Subtitle/Partials/DownloadModal";
import {useConfirm} from "@/Components/ConfirmDialog";

type EditSubtitleProps = {
    job: PersistedJob,
    content: string,
    languages: string[],
    pendingLanguages: string[],
    currentLanguage: string,
    lastModified: string | null
    translate?: boolean
}

export default function ({
    auth,
    job,
    content,
    lastModified,
    translate,
    languages,
    pendingLanguages,
    currentLanguage
}: PageProps<EditSubtitleProps>) {
    const [currentItemIdx, setCurrentItemIdx] = useState<number | null>(null);
    const [pendingLanguageNames, setPendingLanguageNames] = useState<string[]>([]);
    const [mediaFile, setMediaFile] = useState<File | null>(null);
    const [mediaUrl, setMediaUrl] = useState<string | null>(null);
    const [saving, setSaving] = useState<boolean>(false);
    const [showTranslateModal, setShowTranslateModal] = useState<boolean>(false);
    const [showDownloadModal, setShowDownloadModal] = useState<boolean>(false);
    const [showDetailsModal, setShowDetailsModal] = useState<boolean>(false);
    const [showSearchModal, setShowSearchModal] = useState<boolean>(false);
    const [showFeedbackModal, setShowFeedbackModal] = useState<boolean>(false);
    const [showEditNameModal, setShowEditNameModal] = useState<boolean>(false);
    const [subtitleTrackUrl, setSubtitleTrackUrl] = useState<string | null>(null);
    const [currentMediaTime, setCurrentMediaTime] = useState<number>(0);
    const [lastModifiedAt, setLastModifiedAt] = useState<Moment | null>(() => {
        return lastModified ? moment(lastModified) : null;
    });
    const mediaPlayerRef = React.createRef<HTMLVideoElement>();
    const initialItems = useMemo(() => parseSrt(content), [content]);
    const {items, updateItems, addItem, undo, redo} = useHistory<Subtitle>(initialItems);
    const confirm = useConfirm();

    useEffect(() => {
        const video = mediaPlayerRef.current;
        if (video) {
            video.addEventListener('timeupdate', () => {
                setCurrentMediaTime(video.currentTime);
            })
        }
    }, []);

    useEffect(() => {
        setPendingLanguageNames(pendingLanguages);
    }, [pendingLanguages]);

    const saveRevision = async () => {
        const url = route('subtitle.updateContent', job.id);
        try {
            await axios.put(url, {
                content: subtitlesToSrt(items),
                language: currentLanguage
            });
        } catch (e) {
            toast.error('Failed to save changes');
        }
        setSaving(false);
    }
    const autoSave = useDebouncedCallback(saveRevision, 5000);

    useEffect(() => {
        const handleBeforeUnload = (event: BeforeUnloadEvent) => {
            if (saving) {
                event.preventDefault();
                saveRevision();
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [saving]);

    useEffect(() => {
        if (undo || redo) {
            setSaving(true);
            autoSave();
            setLastModifiedAt(moment());
        }
    }, [items])

    useEffect(() => {
        if (window.file_cache[job.job_name]) {
            setMediaFile(window.file_cache[job.job_name]);
        }
    }, []);

    useEffect(() => {
        if (mediaFile) {
            const url = URL.createObjectURL(mediaFile);
            setMediaUrl(url);
            setCurrentItemIdx(0)
        }
    }, [mediaFile]);

    useEffect(() => {
        if (translate) {
            setShowTranslateModal(true);
            router.replace(route('subtitle.edit', job.id), {preserveState: true});
        }
    }, [translate]);

    useEffect(() => {
        if (currentItemIdx !== null) {
            let idx = 0;
            let minDiff = Number.MAX_VALUE;
            items.forEach((item, index) => {
                let diff = Math.abs(item.startTimeSeconds - currentMediaTime);
                if (currentMediaTime <= item.endTimeSeconds) {
                    diff = Math.min(diff, Math.abs(item.endTimeSeconds - currentMediaTime));
                }
                if (diff < minDiff) {
                    minDiff = diff;
                    idx = index;
                }
            });
            setCurrentItemIdx(idx);
        }
    }, [currentMediaTime]);

    useEffect(() => {
        if (items.length > 0) {
            setSubtitleTrackUrl(getVttTrackUrl(items))
        }
    }, [items]);

    const setTimeFromSubtitle = (index: number) => {
        const video = mediaPlayerRef.current;
        if (video) {
            video.currentTime = items[index].startTimeSeconds;
        }
    }

    const addNewItem = (prev: Subtitle, next?: Subtitle) => {
        const parser = new SrtParser();
        if (prev.endTimeSeconds === next?.startTimeSeconds) {
            return; // no room for new item
        }
        const startTimeSeconds = parser.timestampToSeconds(prev.endTime);
        const endTimeSeconds = next ? parser.timestampToSeconds(next.startTime) : startTimeSeconds + 0.1;
        const newItem: Subtitle = {
            id: Date.now().toString(),
            startTime: parser.secondsToTimestamp(startTimeSeconds * 1000),
            endTime: parser.secondsToTimestamp(endTimeSeconds * 1000),
            text: '',
            startTimeSeconds,
            endTimeSeconds
        }
        addItem(newItem);
    }

    const updateName = (name: string) => {
        const url = route('subtitle.rename', job.id);
        axios.post(url, {name}).then(() => {
            router.reload({preserveState: true});
        }).catch(console.error);
    }

    const restoreOriginalContent = () => {
        const url = route('subtitle.restoreContent', job.id);
        axios.post(url).then(() => {
            document.location.reload();
        }).catch(err => {
            const msg = err.response?.data?.message || 'Failed to restore original content';
            toast.error(msg);
        });
    }

    const viewLanguage = (language: string) => {
        const data: any = {language}
        router.reload({data, preserveState: true, replace: true});
    }

    const getPendingTranslationStatus = async (language: string) => {
        const url = route('subtitle.translationStatus', job.id);
        const res = await axios.get(url, {
            params: {language}
        });
        const status = res.data.status;
        if (status === 'failed') {
            toast.error(`${language} translation failed`);
            setPendingLanguageNames(prev => prev.filter(l => l !== language));
        } else if (status === 'completed') {
            viewLanguage(language);
        } else {
            toast('Translation in progress');
        }
    }

    const download = (format: SubtitleFormat, options: Record<any, any>) => {
        downloadSubtitles(
            format,
            items,
            makeFileName(job.file_name, format, true),
            options
        );
    }

    const getVttTrackUrl = (subs: Subtitle[]) => {
        const content = subtitlesToVtt(subs);
        const blob = new Blob([content], {type: 'text/vtt'});
        return URL.createObjectURL(blob);
    }

    const makeFileName = (name: string, ext: string, withLang: boolean) => {
        let parts = name.split('.');
        if (parts.length > 1) {
            parts.pop();
        }
        return `${parts.join('.')}${withLang ? `-${currentLanguage.toLowerCase()}` : ''}.${ext}`;
    }

    const deleteSelf = async () => {
        if (await confirm('Are you sure you want to delete?')) {
            await deleteJob(job)
            toast.success('Subtitles deleted')
            router.replace(route('dashboard'))
        }
    }

    const onTranslateComplete = (jobId: string[], language: string, method: TranslationMethod) => {
        if (jobId.length > 0) {
            setShowTranslateModal(false)
            toast.success('Subtitles translated')
            if (method === TranslationMethod.AI) {
                getPendingTranslationStatus(language.toLowerCase());
            } else {
                viewLanguage(language);
            }
        }
    }

    return (
        <div className="h-screen">
            <FeedbackModal
                onClose={() => setShowFeedbackModal(false)}
                onSubmitted={() => {
                    setShowFeedbackModal(false);
                    toast.success('Feedback submitted');
                }}
                job={showFeedbackModal ? job : null}
            />
            <TranslateModal
                isFreeTrial={auth.user.is_trial}
                show={showTranslateModal}
                translatedLanguages={[...languages, ...pendingLanguageNames]}
                onTranslateComplete={onTranslateComplete}
                jobs={[job]}
                subtitles={{[job.id]: items}}
                onClose={(inProgressLanguage) => {
                    setShowTranslateModal(false);
                    if (inProgressLanguage) {
                        setPendingLanguageNames(prev => [...prev, inProgressLanguage]);
                    }
                }}
            />
            <ExtractionJobDetailsModal job={job} show={showDetailsModal} onClose={() => setShowDetailsModal(false)}/>
            <SearchModal
                items={items}
                onUpdated={changes => {
                    updateItems(changes)
                    setShowSearchModal(false)
                }}
                show={showSearchModal}
                onClose={() => setShowSearchModal(false)}
            />
            <EditNameModal
                show={showEditNameModal}
                onClose={() => setShowEditNameModal(false)}
                name={job.file_name}
                onUpdated={(name) => {
                    updateName(name)
                    setShowEditNameModal(false)
                }}
            />
            <DownloadModal
                show={showDownloadModal}
                onClose={() => setShowDownloadModal(false)}
                onDownload={download}
            />
            <AuthenticatedLayout
                mainClassName="flex grow min-h-0"
                containerClassName="h-full flex flex-col bg-gray-100"
                showFooter={false}
                header={
                    <div className="flex md:items-center flex-col md:flex-row">
                        <div className="flex grow items-center">
                            <button type="button"
                                    onClick={() => window.history.back()}
                                    className="py-1.5 px-3 me-4 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 hidden md:inline-block">
                                <FontAwesomeIcon
                                    className={`align-middle w-4 h-4 items-center `}
                                    icon={faArrowLeft}

                                />
                            </button>
                            <div>
                                <h2 className="font-semibold text-lg md:text-xl text-gray-800 leading-tight hidden md:inline">
                                    Edit Subtitles
                                </h2>
                            </div>
                        </div>
                        <div className=" flex items-center flex-row-reverse md:flex-row justify-between">
                        <span>{saving && (
                            <span className="text-gray-400 hidden lg:inline text-sm mr-5"><FontAwesomeIcon
                                icon={faRotate}
                                className={`mr-1`}
                            /> Saving...</span>
                        )}</span>
                            <div className="flex">
                                <button type="button"
                                        onClick={() => window.history.back()}
                                        className="px-4 py-2 me-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700  md:hidden">
                                    <FontAwesomeIcon
                                        className={`align-middle w-4 h-4 items-center `}
                                        icon={faArrowLeft}

                                    />
                                </button>
                                {
                                    undo && (
                                        <Tooltip theme={{target: `inline-flex`}} content="Undo">
                                            <SecondaryButton onClick={undo}>
                                                <FontAwesomeIcon
                                                    icon={faRotateLeft}
                                                    className={``}
                                                />
                                            </SecondaryButton>
                                        </Tooltip>
                                    )
                                }
                                {
                                    redo && (
                                        <Tooltip theme={{target: `hidden md:inline-flex `}} content="Redo">
                                            <SecondaryButton onClick={redo}>
                                                <FontAwesomeIcon
                                                    icon={faRotateRight}
                                                    className={``}
                                                />
                                            </SecondaryButton>
                                        </Tooltip>
                                    )
                                }

                                <SecondaryButton title="Find and Replace"
                                                 className="hidden md:inline-flex items-center"
                                                 onClick={() => setShowSearchModal(true)}><FontAwesomeIcon
                                    icon={faSearch}
                                    className={` md:me-2`}
                                /><span className="hidden md:inline">Find</span></SecondaryButton>
                                <Dropdown>
                                    <Dropdown.Trigger>
                                        <SecondaryButton
                                            onClick={e => {
                                                if (languages.length <= 1 && pendingLanguages.length == 0) {
                                                    setShowTranslateModal(true)
                                                    e.stopPropagation()
                                                }
                                            }}>
                                            <TranslateIcon/>
                                            <span className="hidden md:inline">Translations</span>
                                            <svg className="ms-2 inline h-5 w-5"
                                                 xmlns="http://www.w3.org/2000/svg"
                                                 viewBox="0 0 20 20" fill="currentColor">
                                                <path fillRule="evenodd"
                                                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                                      clipRule="evenodd"></path>
                                            </svg>
                                        </SecondaryButton>

                                    </Dropdown.Trigger>
                                    <Dropdown.Content className="sm:mt-1 min-w-60" align={"left"}>
                                        {
                                            languages.map((lang, idx) => (
                                                <Dropdown.Item key={idx} onClick={() => viewLanguage(lang)}
                                                               className={`${currentLanguage === lang ? 'font-bold' : ''}`}>
                                              <span className={`flex w-full justify-between`}>
                                                  {ucFirst(lang || 'Unknown')}
                                                  {idx === 0 && <span
                                                      className="bg-blue-100 text-blue-800 text-xs font-medium px-2.5 py-0.5 rounded">Original</span>}
                                              </span>
                                                </Dropdown.Item>
                                            ))
                                        }
                                        {
                                            pendingLanguageNames.map((lang, idx) => (
                                                <Dropdown.Item onClick={() => getPendingTranslationStatus(lang)}>
                                              <span className={`flex w-full justify-between`}>
                                                  {ucFirst(lang)}
                                                  <span
                                                      className="bg-orange-100 self-start text-orange-800 text-xs font-medium px-2.5 py-0.5 rounded">Translating...</span>
                                              </span>
                                                </Dropdown.Item>
                                            ))
                                        }
                                        <Dropdown.Item className="border-t hover:bg-white"
                                                       onClick={() => setShowTranslateModal(true)}>
                                            <div
                                                className="flex w-full px-3 py-2 mt-1 items-center justify-center bg-gray-100 hover:bg-gray-200 rounded-lg">
                                                <FontAwesomeIcon
                                                    icon={faPlus}
                                                    className={`me-2`}
                                                /><span className="">Add Language</span>
                                            </div>
                                        </Dropdown.Item>
                                    </Dropdown.Content>
                                </Dropdown>

                                <PrimaryButton
                                    title="Download"
                                    onClick={() => setShowDownloadModal(true)}
                                ><FontAwesomeIcon
                                    icon={faDownload}
                                    className={`me-2 `}
                                /><span className="inline me-1">Download</span>
                                </PrimaryButton>


                                <Dropdown>
                                    <Dropdown.Trigger>
                                        <SecondaryButton>
                                            <FontAwesomeIcon
                                                icon={faEllipsisV}
                                                className={``}
                                            />

                                        </SecondaryButton>
                                    </Dropdown.Trigger>
                                    <Dropdown.Content className="sm:mt-1 text-right" align={"right"}>
                                        <Dropdown.Item onClick={() => setShowEditNameModal(true)}>
                                            <FontAwesomeIcon
                                                icon={faPen}
                                                className={`me-2`}
                                            />
                                            Rename</Dropdown.Item>
                                        {
                                            job.method !== 'manual' &&
                                            <Dropdown.Item onClick={() => setShowFeedbackModal(true)}>
                                                <FontAwesomeIcon
                                                    className="text-gray-500 w-4 h-4 pr-2 flex self-end"
                                                    icon={faMessage}/>
                                                Give Feedback</Dropdown.Item>
                                        }
                                        <Dropdown.Item onClick={() => setShowDetailsModal(true)}>
                                            <svg className={`me-2 -ms-0.5 size-5`} xmlns="http://www.w3.org/2000/svg"
                                                 fill="none" viewBox="0 0 24 24"
                                                 stroke-width="1.5" stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round"
                                                      d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"/>
                                            </svg>

                                            Details</Dropdown.Item>
                                        <Dropdown.Item onClick={deleteSelf}>
                                            <FontAwesomeIcon
                                                icon={faTrashCan}
                                                className={`me-2`}
                                            />
                                            Delete</Dropdown.Item>
                                    </Dropdown.Content>
                                </Dropdown>

                            </div>
                        </div>
                    </div>
                }
            >
                <Head title="Edit Subtitles"/>
                <div className="grow min-h-0">
                    <div className="container mx-auto flex flex-col h-full">
                        <div className="flex flex-col-reverse lg:flex-row h-full relative">
                            <div className="absolute  lg:w-2/4  -top-1 w-full h-1 bg-transparent top-shadow"></div>
                            <div className="lg:w-2/4 h-full">
                                <SubtitleList
                                    addItem={addNewItem}
                                    onSetCurrentItemIdx={idx => setTimeFromSubtitle(idx)}
                                    items={items}
                                    currentItemIdx={currentItemIdx}
                                    deleteItem={(index) => {
                                        updateItems([{index, item: {...items[index], deleted: true}}])
                                    }}
                                    className={`bg-white border border-gray-200 h-full rounded-lg lg:rounded-none`}
                                    updateItem={(index, updatedItem) => {
                                        updateItems([{index, item: updatedItem}])
                                    }}/>
                            </div>

                            <div className="lg:w-2/4 lg:h-full mb-4 lg:mb-0 lg:ml-4 ">
                                <div className="mt-3 md:mt-6 md:ml-0 py-2 md:py-4 w-full ">
                                    <div className="text-base mx-4 md:mx-8 ">
                                        <div className="overflow-hidden">
                                        <span
                                            onClick={() => setShowEditNameModal(true)}
                                            className="font-bold block cursor-pointer hover:underline max-w-[80vw]
                                                       truncate whitespace-nowrap text-sm md:text-base
                                                       md:whitespace-normal md:line-clamp-2 2xl:line-clamp-none"
                                        >
                                            {job.file_name}
                                        </span>
                                        </div>
                                        <div>
                                            <ClientRender>
                                                <Popover trigger="click"
                                                         content={<span className="p-4 inline-block text-sm">
                                                            {
                                                                lastModifiedAt ? (<span
                                                                        className="text-blue-600 cursor-pointer hover:underline"
                                                                        onClick={restoreOriginalContent}>Restore Original Content</span>) :
                                                                    <span
                                                                        className="text-gray-500">No changes made</span>
                                                            }
                                                     </span>}>

                                                <span
                                                    title="Last Modified"
                                                    suppressHydrationWarning
                                                    className="text-gray-500 text-sm inline-block mb-2 cursor-pointer"> {moment(lastModifiedAt || job.created_at).format('MMM D, h:mm A')}</span>

                                                </Popover>
                                            </ClientRender>
                                        </div>
                                        <Tooltip theme={{target: `inline-flex`}} content="Video Duration">
                                        <span
                                            className="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded">{secondsToDuration(job.file_duration)}</span>
                                        </Tooltip>
                                        <Tooltip theme={{target: `inline-flex`}} content="Language">
                                    <span
                                        className="bg-white text-xs font-medium px-2.5 py-0.5 rounded">{ucFirst(currentLanguage || 'auto-detect')}</span>
                                        </Tooltip>

                                    </div>
                                </div>

                                <div className={`relative ${mediaUrl ? '' : 'hidden'} subtitle-media`}>
                                    <button type="button"
                                            onClick={() => setMediaUrl(null)}
                                            title="Remove Media"
                                            className="close absolute right-2 cursor-pointer top-5 rounded-md bg-white opacity-80 hover:opacity-100 p-2 z-10">
                                        <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                             fill="none"
                                             viewBox="0 0 14 14">
                                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                                  strokeWidth="2"
                                                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"></path>
                                        </svg>
                                        <span className="sr-only">Close modal</span>
                                    </button>
                                    <video
                                        ref={mediaPlayerRef}
                                        src={mediaUrl || ''} controls={true}
                                        className={`mt-4 lg:ml-4 w-full rounded-lg max-h-[30vh] lg:max-h-[60vh]`}
                                    >
                                        {subtitleTrackUrl &&
                                            <track src={subtitleTrackUrl} kind="subtitles" label={currentLanguage}
                                                   default/>}
                                    </video>
                                </div>

                                {
                                    !mediaUrl &&
                                    <div
                                        className="mt-4 hidden md:block lg:ml-4 pt-3 md:py-8 lg:py-16 bg-white border border-gray-200 rounded-lg shadow-sm w-full">
                                        <MediaFileInput
                                            multiple={false}
                                            onFilesSelected={files => {
                                                setMediaFile(files[0])
                                            }}
                                            canSelectUrl={false}/>
                                    </div>
                                }


                            </div>
                        </div>
                    </div>
                </div>
            </AuthenticatedLayout>
        </div>
    )
}
