import PrimaryButton from '@/Components/PrimaryButton';
import {useForm} from '@inertiajs/react';
import {Transition} from '@headlessui/react';
import React, {FormEventHandler} from 'react';
import {UserSettings} from '@/types';

export default function UpdateSettingsForm({settings}: {
    settings: UserSettings
}) {
    const {data, setData, patch, errors, processing, recentlySuccessful} = useForm(
        settings
    );

    const submit: FormEventHandler = (e) => {
        e.preventDefault();
        patch(route('setting.update'));
    };

    return (
        <section>
            <header>
                <h2 className="text-lg font-medium text-gray-900">Preferences</h2>

                <p className="mt-1 text-sm text-gray-600">
                    Update your preferences.
                </p>
            </header>

            <form onSubmit={submit} className="mt-6 space-y-6">
                <div>

                    <div className="flex">
                        <div className="flex items-center h-5">
                            <input checked={data.downloadOnComplete} id="download-complete" type="checkbox"
                                   onChange={(e) => setData('downloadOnComplete', e.target.checked)}
                                   className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"/>
                        </div>
                        <div className="ms-2 text-sm">
                            <label htmlFor="download-complete" className="font-medium text-gray-900">Download subtitles
                                when extraction completes</label>
                            <p id="helper-checkbox-text"
                               className="text-xs font-normal text-gray-500">Automatically download the subtitles when
                                the extraction completes.</p>
                        </div>
                    </div>
                </div>

                <div>
                    <div className="flex">
                        <div className="flex items-center h-5">
                            <input checked={data.extractAudioClientSide} id="extract-audio" type="checkbox"
                                   onChange={(e) => setData('extractAudioClientSide', e.target.checked)}
                                   className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"/>
                        </div>
                        <div className="ms-2 text-sm">
                            <label htmlFor="extract-audio" className="font-medium text-gray-900">Extract audio during
                                upload</label>
                            <p id="helper-checkbox-text"
                               className="text-xs font-normal text-gray-500">For audio-based extractions, extract the
                                audio before uploading, to save time on upload.
                                <br/> <span className="font-semibold">Note:</span> For small files or mobile devices,
                                this feature may not be available.
                            </p>
                        </div>

                    </div>
                </div>

                <div className="flex items-center gap-4">
                    <PrimaryButton disabled={processing}>Save</PrimaryButton>

                    <Transition
                        show={recentlySuccessful}
                        enter="transition ease-in-out"
                        enterFrom="opacity-0"
                        leave="transition ease-in-out"
                        leaveTo="opacity-0"
                    >
                        <p className="text-sm text-gray-600">Saved.</p>
                    </Transition>
                </div>
            </form>
        </section>
    );
}
