import {IconParams} from "@/types";

export default function CloseIcon(args: IconParams) {
    return (
        <svg
            {...args}
            className={[`w-3 h-3`, args.className].join(' ')}
            aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
            viewBox="0 0 14 14">
            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"></path>
        </svg>
    )
}
