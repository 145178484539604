import Select from "react-select";
import languages from "@/services/translate/translationLanguages";

export type TranslateModalProps = {
    onSelect: (language: string) => void;
    selectedLanguage: string;
}

const customStyles = {
    menuPortal: (base: any) => ({
        ...base,
        zIndex: 9999
    })
};

export default function ({onSelect, selectedLanguage}: TranslateModalProps) {
    const initialValue = selectedLanguage ?
        {
            label: languages[selectedLanguage as keyof typeof languages],
            value: selectedLanguage
        } : undefined;

    return (
        <div className="goog-te-gadget" dir="ltr" style={{}}>
            <div id=":0.targetLanguage">
                <Select
                    placeholder={'Select language'}
                    menuPortalTarget={document.body ? document.body : undefined}
                    menuPlacement="top"
                    styles={customStyles}
                    defaultValue={initialValue}
                    inputId="lang-input"
                    onChange={(e) => onSelect(e!.value)}
                    options={Object.entries(languages).map(([value, label]) => (
                        {label, value}
                    ))}/>
            </div>
        </div>

    )
}
