import {bytesToSize, formatMinutes} from "@/utils/textFormatting";
import {ExtractionMethod} from "@/types/enums";
import {Tooltip} from "flowbite-react";
import TrashIcon from "@/Components/Icons/TrashIcon";
import ProgressBar from "@/Components/ProgressBar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBolt, faTriangleExclamation} from "@fortawesome/free-solid-svg-icons";
import {LocalUpload, RemoteUpload} from "@/Pages/Dashboard/Upload/UploadModal";
import Popover from "@/Components/Popover";

type UploadItemProps = {
    upload: LocalUpload | RemoteUpload;
    method: ExtractionMethod;
    showAdvancedSettings: () => void;
    cancelUpload: () => void;
    fileDuration?: number;
    uploadEta?: number;
    disableAudioExtraction: () => void;
};

export default function UploadItem({
    upload,
    fileDuration,
    uploadEta,
    method,
    cancelUpload,
    disableAudioExtraction,
    showAdvancedSettings
}: UploadItemProps) {
    const disablePopover = !('step' in upload) || upload.step !== "extracting";

    return (
        <div key={upload.id} className="group">
            <div className="flex mb-2 items-center gap-x-3">
        <span
            className="size-8 flex flex-shrink-0 justify-center items-center border border-gray-200 text-gray-500 rounded-lg">
            {
                upload.isAudioOnly ? (
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                             stroke="currentColor" className="size-5">
                            <path strokeLinecap="round" strokeLinejoin="round"
                                  d="M19.114 5.636a9 9 0 0 1 0 12.728M16.463 8.288a5.25 5.25 0 0 1 0 7.424M6.75 8.25l4.72-4.72a.75.75 0 0 1 1.28.53v15.88a.75.75 0 0 1-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.009 9.009 0 0 1 2.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75Z"/>
                        </svg>)
                    :

                    (<svg
                            className="size-5"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        >
                            <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"/>
                            <polyline points="17 8 12 3 7 8"/>
                            <line x1="12" x2="12" y1="3" y2="15"/>
                        </svg>
                    )

            }



        </span>
                <div className="flex flex-col grow overflow-hidden ">
                    <p className="text-sm text-ellipsis truncate font-medium text-gray-800">
                        {"file" in upload ? upload.file.name : upload.fileName}
                    </p>

                    <p className="text-xs text-gray-500">
                        <Popover
                            delay={0}
                            disabled={disablePopover}
                            trigger="hover"
                            placement="bottom"
                            aria-labelledby="default-popover"
                            content={
                                <div className="w-80 text-sm text-gray-500 dark:text-gray-400">
                                    <div className="px-6 py-5">
                                        <div className="flex items-center  mb-3">
                                            <FontAwesomeIcon icon={faBolt} className="mr-2 text-orange-400"/>
                                            <h3 className="text-base font-bold">Accelerated Audio Uploads</h3>
                                        </div>
                                        <p>To optimize large video uploads, our system automatically extracts the audio
                                            from your video, before uploading, to reduce overall upload time.
                                        </p>
                                        <button type="button"
                                                onClick={disableAudioExtraction}
                                                className="mt-4 mb-1 px-3 py-2 text-xs font-medium  text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10  ">
                                            Disable Audio Extraction
                                        </button>
                                    </div>
                                </div>
                            }
                        >
                            <span className={`${disablePopover ? '' : 'cursor-pointer'}`}>
                                <span>
                                  {bytesToSize("file" in upload ? upload.file.size : upload.fileSize)}
                                </span>
                                {"file" in upload && upload.step === "extracting" && (
                                    <span> | Extracting audio...</span>
                                )}
                                {upload.progress < 1 && uploadEta !== undefined && (
                                    <span>
                                        {` | ${formatMinutes(
                                            uploadEta / 60,
                                            true
                                        )} left`}
                                    </span>
                                )}
                                {fileDuration !== undefined && upload.progress >= 1 && (
                                    <span>{` | ${formatMinutes(fileDuration / 60)} long`}</span>
                                )}
                            </span>
                        </Popover>
                    </p>

                </div>
                <div className="flex items-center">
                    {method === ExtractionMethod.OCR && !upload.isAudioOnly && (
                        <Tooltip
                            content={"Advanced Settings"}
                            trigger="hover"
                            placement="top"
                        >
              <span onClick={showAdvancedSettings}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className={`w-5 h-5 text-gray-300 mr-0.5   group-hover:text-gray-400 transition-all hover:!text-gray-800 cursor-pointer ${
                        Object.keys(upload.options || {}).length
                            ? "!text-blue-500 !opacity-100"
                            : ""
                    }`}
                >
                  <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.325.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 0 1 1.37.49l1.296 2.247a1.125 1.125 0 0 1-.26 1.431l-1.003.827c-.293.241-.438.613-.43.992a7.723 7.723 0 0 1 0 .255c-.008.378.137.75.43.991l1.004.827c.424.35.534.955.26 1.43l-1.298 2.247a1.125 1.125 0 0 1-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.47 6.47 0 0 1-.22.128c-.331.183-.581.495-.644.869l-.213 1.281c-.09.543-.56.94-1.11.94h-2.594c-.55 0-1.019-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 0 1-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 0 1-1.369-.49l-1.297-2.247a1.125 1.125 0 0 1 .26-1.431l1.004-.827c.292-.24.437-.613.43-.991a6.932 6.932 0 0 1 0-.255c.007-.38-.138-.751-.43-.992l-1.004-.827a1.125 1.125 0 0 1-.26-1.43l1.297-2.247a1.125 1.125 0 0 1 1.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.086.22-.128.332-.183.582-.495.644-.869l.214-1.28Z"
                  />
                  <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                  />
                </svg>
              </span>
                        </Tooltip>
                    )}
                    <Tooltip content={"Remove"} trigger="hover" placement="top">
            <span
                className="text-gray-300  cursor-pointer transition-all group-hover:text-gray-400 hover:!text-gray-800"
                onClick={cancelUpload}
            >
              <TrashIcon className="size-4"/>
            </span>
                    </Tooltip>
                </div>
            </div>

            <div className="flex flex-row items-center">
                <ProgressBar
                    progress={upload.progress * 100}
                    height="sm"
                    color={
                        upload.error
                            ? "red"
                            : "file" in upload && upload.step === "extracting"
                                ? "orange"
                                : "blue"
                    }
                />
                <span className="text-xs ml-1 w-8 text-right">
          {Math.round(upload.progress * 100)}%
        </span>
            </div>
            {upload.error && (
                <div className="flex items-center mt-2 text-red-500">
                    <FontAwesomeIcon icon={faTriangleExclamation} className="mr-2"/>
                    <span className="text-sm">{upload.error}</span>
                </div>
            )}
        </div>
    );
}
