import {PageProps} from "@/types";
import PageLayout from "@/Layouts/PageLayout";
import {Head, Link, usePage} from "@inertiajs/react";
import {faBook, faCode, faKey, faUpRightFromSquare} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";

export type ApiLayoutProps = {
    children: React.ReactNode
}
export default function ({children}: ApiLayoutProps) {
    const {auth: {user}} = usePage<PageProps>().props

    return (
        <>
            <Head title="Subtitle Extraction API">
                <meta name="description" content="Extract subtitles from videos using a simple API."/>
            </Head>
            <PageLayout header={auth => auth ?
                <h1 className="font-semibold text-xl text-gray-800 leading-tight">API</h1> : null}>
                {
                    (authenticated) => (
                        <div className="container mx-auto lg:flex">
                            <aside id="sidebar"
                                   className="w-full lg:w-1/5 px-2 lg:px-0 lg:ml-6 lg:border-r lg:pr-5 mt-3 lg:mt-10">
                                <ul className="flex lg:flex-col py-1 list-unstyled fw-normal small lg:overflow-visible overflow-x-auto px-2 lg:px-0 space-x-2 lg:space-x-0 lg:space-y-2">
                                    <li className="flex-shrink-0">
                                        <Link
                                            href={route('api-info')}
                                            className={`flex items-center p-2 text-base text-gray-900 rounded-lg hover:bg-gray-200 group ${route().current('api-info') ? (user ? 'bg-white' : 'bg-gray-100') : ''} `}
                                        >
                                            <FontAwesomeIcon
                                                icon={faCode}
                                                className={route().current('api-info') ? 'text-blue-500' : 'text-gray-500'}
                                            />
                                            <span className="ml-3">API Overview</span>
                                        </Link>
                                    </li>
                                    {
                                        authenticated && (
                                            <li className="flex-shrink-0">
                                                <Link
                                                    href={route('api-keys.index')}
                                                    className={`flex items-center p-2 text-base text-gray-900 rounded-lg hover:bg-gray-200 group ${route().current('api-keys.*') ? 'bg-white' : ''} `}
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faKey}
                                                        className={route().current('api-keys.*') ? 'text-blue-500' : 'text-gray-500'}
                                                    />
                                                    <span className="ml-3">API Keys</span>
                                                </Link>
                                            </li>
                                        )
                                    }
                                    <li className="flex-shrink-0">
                                        <a href={route('api-docs')}
                                           target={"_blank"}
                                           className="flex items-center p-2 text-base text-gray-900 rounded-lg hover:bg-gray-200 group">
                                            <FontAwesomeIcon icon={faBook} className="text-gray-500"/>
                                            <span className="ml-3 mr-1">Documentation </span>
                                            <FontAwesomeIcon
                                                icon={faUpRightFromSquare}
                                                className="w-3 h-3"
                                            />
                                        </a>
                                    </li>
                                </ul>
                            </aside>
                            <hr className="lg:hidden mt-3"/>
                            <div className="min-h-[70vh] w-full lg:w-4/5 lg:flex-col">
                                {children}
                            </div>
                        </div>
                    )
                }
            </PageLayout>
        </>
    )
}
