import {Head, Link} from '@inertiajs/react';
import {PageProps} from '@/types';
import LandingPageHeader from "@/Pages/Landing/Partials/LandingPageHeader";
import HowItWorks, {HowItWorksProps} from "@/Pages/Landing/Partials/HowItWorks";
import GuestLayout from "@/Layouts/Guest/GuestLayout";

const steps: HowItWorksProps['steps'] = [
    {
        title: "Upload Your Videos.",
        description: "Upload any video format directly to our platform with ease."
    },
    {
        title: "We Extract the Subtitles",
        description: "Our AI quickly processes your video, extracting subtitles with precision."
    },
    {
        title: "Subtitles Ready to Go",
        description: "Grab your SRT file, neatly prepared for any further translations or edits."
    }
]

export default function HardcodedSubtitles({}: PageProps) {
    return (
        <GuestLayout>
            <Head title="Welcome">
                <title>Extract hardcoded subtitles from video online</title>
                <meta name="description"
                      content="Extract hardcoded subtitles from videos online. Our AI-powered tool quickly and accurately generates subtitle files in various formats."/>
            </Head>
            <LandingPageHeader
                buttonText="Start Extracting for Free"
                title="Extract Hardcoded Subtitles from Videos Online"
                description="Extract hardcoded subtitles from videos online swiftly and accurately with our user-friendly, AI-powered tool"
            />
            <div className="py-10"></div>
            <HowItWorks steps={steps}/>
            <div className="py-6"></div>
            <div className="w-full lg:max-w-screen-xl mx-auto px-5">
                <div className="flex flex-col md:flex-row items-center justify-center space-x-4">

                    <div className="md:mr-10 lg:mr-40 flex-shrink-0">
                        <img alt="Extract subtitles with automatic language detection"
                             className="object-contain md:w-[550px] shadow-md border-gray-300"
                             src="/img/select-language-screenshot.png"/>
                    </div>


                    <div className="text-center md:text-start mt-5 md:mt-0">
                        <h2 className="text-2xl font-bold text-gray-900 mb-4">Auto-Detect Language</h2>
                        <p className="text-gray-600">Our system automatically identifies the language used in your
                            videos with high accuracy.
                            Prefer to specify the language yourself? No problem. You can manually select from a
                            comprehensive list of over 50 languages, ensuring precise and contextual
                            translations.</p>
                    </div>
                </div>
            </div>
            <div className="py-6"></div>
            <div className="w-full lg:max-w-screen-xl mx-auto px-5">
                <div className="flex flex-col md:flex-row-reverse items-center justify-center space-x-4 ">

                    <div className="md:mr-10 lg:ml-40 flex-shrink-0">
                        <img alt="Detecting video subtitle bounds from hardcoded subtitles"
                             className="object-contain md:w-[550px] rounded-lg "
                             src="/img/detecting-video-subtitle-bounds.png"/>
                    </div>


                    <div className="text-center md:text-start mt-5 md:mt-0">
                        <h2 className="text-2xl font-bold text-gray-900 mb-4">Effortless Subtitle Capture</h2>
                        <p className="text-gray-600">Equipped to handle even the most complex scenarios, including
                            noisy backgrounds and varying text positions, our technology ensures that no subtitle
                            goes undetected.</p>
                    </div>
                </div>
            </div>

            <div className="py-6"></div>
            <div className="w-full lg:max-w-screen-xl mx-auto px-5">
                <div className="flex flex-col md:flex-row items-center justify-center space-x-4">

                    <div className="md:mr-10 lg:mr-40 flex-shrink-0">
                        <img alt="Extract subtitles with automatic language detection"
                             className="object-contain md:w-[550px] shadow-md border-gray-300"
                             src="/img/subtitle-extraction-files.png"/>
                    </div>


                    <div className="text-center md:text-start mt-5 md:mt-0">
                        <h2 className="text-2xl font-bold text-gray-900 mb-4">Fast Subtitle Extraction</h2>
                        <p className="text-gray-600">Get subtitles from your videos in 1-5 minutes—no long waits, just
                            fast and reliable extraction.</p>
                    </div>
                </div>
            </div>


            <section className="bg-gray-50 py-14 my-10">
                <div className="lg:max-w-screen-xl mx-auto">
                    <div className="p-4 sm:p-8  bg-white shadow sm:rounded-lg">
                        <h2 className="text-3xl font-bold text-gray-900 mb-8">How to extract hardcoded subtitles from
                            videos</h2>
                        <ol className="list-decimal list-inside space-y-4">
                            <li>
                                <strong className="font-bold">Upload the video file</strong>
                                <p className="text-gray-700">Select the video file that you want to extract subtitles
                                    from.</p>
                            </li>
                            <li>
                                <strong className="font-bold">Chose the language</strong>
                                <p className="text-gray-700">Select the language, or let our AI detect it for you.</p>
                            </li>
                            <li>
                                <strong className="font-bold">Extract.</strong>
                                <p className="text-gray-700">Click on the extract button and wait for the process to
                                    finish.</p>
                            </li>
                            <li>
                                <strong className="font-bold">Download your SRT file.</strong>
                                <p className="text-gray-700">The text will be extracted and you can download the SRT
                                    file.</p>
                            </li>

                        </ol>
                    </div>
                </div>
            </section>
            <div className="flex flex-col items-center justify-center space-y-4">
                <Link
                    href={route('register')}
                >
                    <button
                        className="rounded text-lg  w-fit transition focus-visible:ring-2 ring-offset-2 ring-gray-200 px-5 py-2.5  hover:bg-gray-500 hover:text-white  border-2 border-gray-500 inline-flex  gap-1 items-center ">
                        Get Started
                    </button>
                </Link>
            </div>
        </GuestLayout>
    );
}
