'use client';

import {
    cloneElement,
    ComponentProps,
    ComponentPropsWithRef,
    Dispatch,
    Fragment,
    isValidElement,
    ReactNode,
    SetStateAction,
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react';
import type {Placement} from '@floating-ui/react';
import {FloatingFocusManager, useMergeRefs} from '@floating-ui/react';
import {FlowbiteFloatingArrowTheme} from "flowbite-react/lib/esm/components/Floating";
import {DeepPartial} from "flowbite-react/lib/esm/types";
import {mergeDeep} from "flowbite-react/lib/esm/helpers/merge-deep";
// @ts-ignore
import {useBaseFLoating, useFloatingInteractions} from "flowbite-react/lib/esm/hooks/use-floating";
import {getArrowPlacement} from "flowbite-react/lib/esm/components/Floating/helpers";
import {Transition} from "@headlessui/react";

export interface FlowbitePopoverTheme {
    arrow: Omit<FlowbiteFloatingArrowTheme, 'style'>;
    base: string;
    content: string;
}

export const popoverTheme: FlowbitePopoverTheme = {
    base: 'absolute z-20 inline-block max-w-[150vw] bg-white outline-none border border-gray-200 rounded-lg shadow-lg',
    content: 'z-10 overflow-hidden rounded-[7px]',
    arrow: {
        base: 'absolute h-2 w-2 z-0 rotate-45 mix-blend-lighten bg-white border border-gray-200 ',
        placement: '-4px',
    },
};

export interface PopoverProps extends Omit<ComponentProps<'div'>, 'content' | 'style'> {
    arrow?: boolean;
    arrowClassName?: string;
    content: ReactNode;
    placement?: 'auto' | Placement;
    theme?: DeepPartial<FlowbitePopoverTheme>;
    trigger?: 'hover' | 'click';
    initialOpen?: boolean;
    open?: boolean;
    onOpenChange?: Dispatch<SetStateAction<boolean>>;
    delay?: number;
    disabled?: boolean;
}

export default function Popover({
    children,
    content,
    theme: customTheme = {},
    arrow = true,
    arrowClassName,
    trigger = 'click',
    initialOpen,
    open: controlledOpen,
    onOpenChange: setControlledOpen,
    placement: theirPlacement = 'bottom',
    delay = 0,
    disabled,
    ...props
}: PopoverProps) {

    if (disabled) {
        return children as JSX.Element;
    }

    const [uncontrolledOpen, setUncontrolledOpen] = useState<boolean>(Boolean(initialOpen));
    const arrowRef = useRef<HTMLDivElement>(null);

    const theme = mergeDeep(popoverTheme, customTheme);

    const open = controlledOpen ?? uncontrolledOpen;
    const setOpen = setControlledOpen ?? setUncontrolledOpen;

    const floatingProps = useBaseFLoating({
        open,
        placement: theirPlacement,
        arrowRef,
        setOpen,
    });

    const [isHovering, setIsHovering] = useState(false);
    const hoverTimeoutRef = useRef<NodeJS.Timeout | null>(null);

    const handleMouseEnter = () => {
        hoverTimeoutRef.current = setTimeout(() => {
            setIsHovering(true);
        }, delay);
    };

    const handleMouseLeave = () => {
        if (hoverTimeoutRef.current) {
            clearTimeout(hoverTimeoutRef.current);
        }
        setIsHovering(false);
    };

    useEffect(() => {
        return () => {
            // Cleanup on component unmount
            if (hoverTimeoutRef.current) {
                clearTimeout(hoverTimeoutRef.current);
            }
        };
    }, []);

    const {
        floatingStyles,
        context,
        placement,
        middlewareData: {arrow: {x: arrowX, y: arrowY} = {}},
        refs,
    } = floatingProps;

    const {getFloatingProps, getReferenceProps} = useFloatingInteractions({
        context,
        role: 'dialog',
        trigger,
    });

    const childrenRef = (children as ComponentPropsWithRef<'button'>).ref;
    const ref = useMergeRefs([context.refs.setReference, childrenRef]);

    const popoverContentProps = trigger === 'hover' ? {
        onMouseEnter: handleMouseEnter,
        onMouseLeave: handleMouseLeave
    } : {};


    if (!isValidElement(children)) {
        throw Error('Invalid target element');
    }

    const target = useMemo(() => {
        return cloneElement(
            children,
            getReferenceProps({
                ref,
                'data-testid': 'flowbite-popover-target',
                onMouseEnter: handleMouseEnter,
                onMouseLeave: handleMouseLeave,
                ...children?.props,
            }),
        );
    }, [children, ref, getReferenceProps]);

    return (
        <>
            {target}
            {(
                <FloatingFocusManager context={context} modal>
                    <Transition
                        as={Fragment}
                        show={trigger === 'hover' ? isHovering && open : open}
                        enter="transition-opacity ease-in duration-150"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div
                            className={theme.base}
                            ref={refs.setFloating}
                            data-testid="flowbite-popover"
                            {...props}
                            style={floatingStyles}
                            {...getFloatingProps()}
                            {...popoverContentProps}
                        >
                            <div className="relative">
                                {arrow && (
                                    <div
                                        className={theme.arrow.base + (arrowClassName ? ` ${arrowClassName}` : '')}
                                        data-testid="flowbite-popover-arrow"
                                        ref={arrowRef}
                                        style={{
                                            top: arrowY ?? ' ',
                                            left: arrowX ?? ' ',
                                            right: ' ',
                                            bottom: ' ',
                                            [getArrowPlacement({placement})]: theme.arrow.placement,
                                        }}
                                    >
                                        &nbsp;
                                    </div>
                                )}
                                <div className={theme.content}>{typeof content === 'string' ? <span
                                    className="text-sm px-3 pt-3 pb-3 inline-block">{content}</span> : content}</div>
                            </div>

                        </div>
                    </Transition>
                </FloatingFocusManager>
            )}
        </>
    );
}
