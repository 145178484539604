import FFmpeg, {VideoMetaData} from "@/services/video/FFmpeg";

const MOUNT_DIR = '/input';
const OUTPUT_DIR = '/output';

export default class VideoProbe {
    private initialized = false;
    private ffmpeg = new FFmpeg();
    private file: File | null = null;
    private videoMetadata: VideoMetaData | null = null;
    private videoElement: HTMLVideoElement | null = null;
    private canUseNativeVideo = false;

    public async load(video: File) {
        this.file = video;

        await this.ffmpeg.load();
        await this.ffmpeg.createDir(OUTPUT_DIR);
        await this.ffmpeg.createDir(MOUNT_DIR);
        // @ts-ignore
        await this.ffmpeg.mount('WORKERFS', {files: [video]}, MOUNT_DIR);

        if (!this.file) {
            return; // unload() was called while waiting for the mount to complete
        }

        await this.loadMetadata(video);
        this.videoElement = document.createElement('video');
        this.videoElement.src = URL.createObjectURL(video);

        try {
            await new Promise((resolve, reject) => {
                this.videoElement!.onloadedmetadata = resolve;
                this.videoElement!.onerror = reject;
            });
            this.canUseNativeVideo = true;
        } catch (error) {
            this.canUseNativeVideo = false;
        }

        this.initialized = true;
    }

    private ensureInitialized() {
        if (!this.initialized) {
            throw new Error('VideoProbe not initialized');
        }
    }

    private async loadMetadata(file: File) {
        if (this.videoMetadata) {
            return;
        }
        this.videoMetadata = await this.ffmpeg.getVideoMetaData(`${MOUNT_DIR}/${file.name}`);
    }

    public getMetaData(): VideoMetaData {
        this.ensureInitialized();
        return this.videoMetadata!;
    }

    public async getFrames(start: number, fps: number, count: number) {
        this.ensureInitialized();
        if (this.canUseNativeVideo) {
            return this.getNativeFrames(start, fps, count);
        } else {
            return this.getFFmpegFrames(start, fps, count);
        }
    }

    private async getNativeFrames(start: number, fps: number, count: number): Promise<string[]> {
        const frames: string[] = [];
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d')!;

        this.videoElement!.currentTime = start;

        for (let i = 0; i < count; i++) {
            await new Promise<void>(resolve => {
                this.videoElement!.onseeked = () => {
                    canvas.width = this.videoElement!.videoWidth;
                    canvas.height = this.videoElement!.videoHeight;
                    ctx.drawImage(this.videoElement!, 0, 0);
                    frames.push(canvas.toDataURL('image/jpeg'));
                    this.videoElement!.currentTime += 1 / fps;
                    resolve();
                };
            });
        }

        return frames;
    }

    private async getFFmpegFrames(start: number, fps: number, count: number): Promise<string[]> {
        const duration = count / fps;
        await this.ffmpeg.exec([
            '-ss', start.toString(),
            '-i', `${MOUNT_DIR}/${this.file!.name}`,
            '-t', duration.toString(),
            '-vf', "select='eq(pict_type,I)',scale=480:320",
            '-vsync', '0',
            '-q:v', '3',
            '-frames:v', count.toString(),
            `${OUTPUT_DIR}/frame%d.jpg`
        ]);

        const frames: string[] = [];
        const files = await this.ffmpeg.listDir(OUTPUT_DIR);
        const images = files.filter(f => f.name.endsWith('.jpg'));
        for (let i = 0; i < images.length; i++) {
            const fileName = `${OUTPUT_DIR}/${images[i].name}`;
            const data = await this.ffmpeg.readFile(fileName);
            const buffer = (data as Uint8Array).buffer;
            const blob = new Blob([buffer], {type: 'image/jpeg'});
            frames.push(URL.createObjectURL(blob));
        }
        return frames;
    }

    public unload() {
        if (this.videoElement) {
            this.videoElement.src = '';
            this.videoElement = null;
        }
        this.videoMetadata = null;
        this.file = null;
        this.initialized = false;
        this.ffmpeg.terminate();
    }
}
