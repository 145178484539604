import InputError from '@/Components/InputError';
import InputLabel from '@/Components/InputLabel';
import PrimaryButton from '@/Components/PrimaryButton';
import TextInput from '@/Components/TextInput';
import { useForm } from '@inertiajs/react';
import { Transition } from '@headlessui/react';
import { FormEventHandler } from 'react';

export type InvoiceSettings = {
    billing_name: string;
    billing_contact: string;
    billing_address_1: string;
    billing_address_2: string;
    billing_address_3: string;
}

export default function InvoiceSettingsForm({ settings, className = '' }: {
    settings: InvoiceSettings;
    className?: string;
}) {
    const { data, setData, patch, errors, processing, recentlySuccessful } = useForm({
        billing_name: settings.billing_name || '',
        billing_contact: settings.billing_contact || '',
        billing_address_1: settings.billing_address_1 || '',
        billing_address_2: settings.billing_address_2 || '',
        billing_address_3: settings.billing_address_3 || '',
    });

    const submit: FormEventHandler = (e) => {
        e.preventDefault();
        patch(route('billing.invoice-settings.update'));
    };

    return (
        <section className={className}>
            <header>

                <p className=" text-base text-gray-600">
                    Optionally configure the custom billing information that appears on your invoices.
                </p>
            </header>

            <form onSubmit={submit} className="mt-6 space-y-6">
                <div>
                    <InputLabel htmlFor="billing_name" value="Name" />
                    <TextInput
                        id="billing_name"
                        className="mt-1 block w-full"
                        value={data.billing_name}
                        onChange={(e) => setData('billing_name', e.target.value)}
                        isFocused
                    />
                    <InputError className="mt-2" message={errors.billing_name} />
                </div>

                <div>
                    <InputLabel htmlFor="billing_contact" value="Contact" />
                    <TextInput
                        id="billing_contact"
                        className="mt-1 block w-full"
                        value={data.billing_contact}
                        onChange={(e) => setData('billing_contact', e.target.value)}
                    />
                    <InputError className="mt-2" message={errors.billing_contact} />
                </div>

                <div>
                    <InputLabel htmlFor="billing_address_1" value="Address 1" />
                    <TextInput
                        id="billing_address_1"
                        className="mt-1 block w-full"
                        value={data.billing_address_1}
                        onChange={(e) => setData('billing_address_1', e.target.value)}
                    />
                    <InputError className="mt-2" message={errors.billing_address_1} />
                </div>

                <div>
                    <InputLabel htmlFor="billing_address_2" value="Address 2" />
                    <TextInput
                        id="billing_address_2"
                        className="mt-1 block w-full"
                        value={data.billing_address_2}
                        onChange={(e) => setData('billing_address_2', e.target.value)}
                    />
                    <InputError className="mt-2" message={errors.billing_address_2} />
                </div>

                <div>
                    <InputLabel htmlFor="billing_address_3" value="Address 3" />
                    <TextInput
                        id="billing_address_3"
                        className="mt-1 block w-full"
                        value={data.billing_address_3}
                        onChange={(e) => setData('billing_address_3', e.target.value)}
                    />
                    <InputError className="mt-2" message={errors.billing_address_3} />
                </div>

                <div className="flex items-center gap-4">
                    <PrimaryButton disabled={processing}>Save</PrimaryButton>

                    <Transition
                        show={recentlySuccessful}
                        enter="transition ease-in-out"
                        enterFrom="opacity-0"
                        leave="transition ease-in-out"
                        leaveTo="opacity-0"
                    >
                        <p className="text-sm text-gray-600">Saved.</p>
                    </Transition>
                </div>
            </form>
        </section>
    );
}
