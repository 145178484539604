import {PageProps} from "@/types";
import Layout from "@/Pages/Api/ApiPageLayout";
import ApiOverview from "@/Pages/Api/ApiOverview";


export default function (props: PageProps) {
    return (
        <>
            <Layout>
                <div className="flex justify-center items-center h-full my-2">
                    <ApiOverview user={props.auth.user}/>
                </div>
            </Layout>
        </>
    )
}
