import React from "react";
import toast from "react-hot-toast";
import {Tooltip} from "flowbite-react";

export type ApiTokenDisplayProps = {
    apiKey: string
}

export default function ({apiKey}: ApiTokenDisplayProps) {

    const copy = () => {
        navigator.clipboard.writeText(apiKey).then(() => {
            toast.success('API key copied to clipboard');
        })
    }

    return (
        <div
            className="p-8 mx-6 mt-8 mb-4 text-sm text-blue-800 rounded-lg bg-blue-50"
            role="alert">
            <span className="font-bold text-lg">API Key Created</span>
            <p>Copy this token to access the API. For security, this will not be shown again.</p>

            <div className=" mt-4 w-full max-w-[30rem]">
                <div className="relative">
                    <label htmlFor="npm-install-copy-button" className="sr-only">Label</label>
                    <input id="npm-install-copy-button" type="text"
                           className="col-span-6 bg-gray-50 border border-gray-300 text-gray-500 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                           value={apiKey} disabled readOnly/>


                    <button onClick={copy}
                            className="absolute end-2 top-1/2 -translate-y-1/2 text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg p-2 inline-flex items-center justify-center">
                        <Tooltip content={"Copy to clipboard"} trigger="hover" placement="top">
                            <span id="default-icon">
                                <svg className="w-3.5 h-3.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                     fill="currentColor"
                                     viewBox="0 0 18 20">
                                    <path
                                        d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2Zm-3 14H5a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2Zm0-4H5a1 1 0 0 1 0-2h8a1 1 0 1 1 0 2Zm0-5H5a1 1 0 0 1 0-2h2V2h4v2h2a1 1 0 1 1 0 2Z"/>
                                </svg>
                            </span>
                        </Tooltip>
                    </button>

                </div>
            </div>

        </div>
    )
}
