import Modal from "@/Components/Modal";
import React, {useEffect, useMemo, useState} from "react";
import CloseIcon from "@/Components/Icons/CloseIcon";
import PrimaryButton from "@/Components/PrimaryButton";
import {PersistedJob} from "@/types";
import moment from "moment";
import axios from "axios";
import toast from "react-hot-toast";

export type FeedbackModalProps = {
    onClose: () => void;
    onSubmitted: () => void;
    job: PersistedJob | null;
};

const HOURS_UNTIL_VIDEO_DELETION = 24

export default function ({onClose, job, onSubmitted}: FeedbackModalProps) {
    const [message, setMessage] = useState<string>('')
    const [shareVideo, setShareVideo] = useState<boolean>(false)
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
    const textareaRef = React.createRef<HTMLTextAreaElement>();
    const canShareVideo = useMemo(() => {
        if (job === null) return false;
        const diff = moment().diff(moment(job.created_at), 'milliseconds');
        return diff < HOURS_UNTIL_VIDEO_DELETION * 60 * 60 * 1000;
    }, [job]);

    const show = job !== null;

    useEffect(() => {
        setMessage('');
    }, [job]);

    useEffect(() => {
        if (canShareVideo) {
            setShareVideo(true);
        }
    }, [canShareVideo]);

    useEffect(() => {
        if (show) {
            setTimeout(() => textareaRef.current?.focus(), 100);
        } else {
            setIsSubmitting(false);
        }
    }, [show]);

    const submitFeedback = async () => {
        if (message.trim() === '') return;
        setIsSubmitting(true)
        const url = route('job.feedback', {job: job!!.id});
        try {
            await axios.post(url, {
                message,
                shareVideo,
            });
            onSubmitted();
        } catch (e) {
            toast.error('Failed to submit feedback. Try again later.');
        } finally {
            setIsSubmitting(false);
        }
    }

    return (
        <Modal maxWidth={'md'} show={show} onClose={onClose}>
            <div id="default-modal" className="w-full">
                <div className="flex items-center p-4 md:p-7 !pb-4">
                    <div className="w-8 h-8"></div>
                    <div className="flex-grow flex justify-center">
                        <h3 className="text-xl font-semibold text-gray-900 flex items-center">
                            Provide Feedback
                        </h3>
                    </div>
                    <button type="button"
                            onClick={onClose}
                            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 inline-flex justify-center items-center"
                    >
                        <CloseIcon/>
                        <span className="sr-only">Close modal</span>
                    </button>
                </div>
                <div className="px-7 pb-7">
                    <p className="text-sm mb-8 text-gray-600 text-center">Subtitles not accurate? Let us know how we
                        can improve.</p>

                    <textarea
                        ref={textareaRef}
                        id="message"
                        rows={6}
                        autoFocus={true}
                        className="block p-2.5 w-full text-sm text-gray-900 rounded-lg shadow-sm border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                        placeholder="Share your feedback..."
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        required
                        autoComplete="Message"
                    />

                    {
                        canShareVideo && (
                            <div className="flex items-center mt-5">
                                <input checked={shareVideo} id="checked-checkbox" type="checkbox"
                                       onChange={(e) => setShareVideo(e.target.checked)}
                                       className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                <label htmlFor="checked-checkbox"
                                       className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Share video
                                    file</label>
                            </div>
                        )
                    }


                    <PrimaryButton
                        disabled={isSubmitting}
                        onClick={submitFeedback}
                        className="mt-5 w-full">Send Feedback</PrimaryButton>
                </div>

            </div>
        </Modal>
    )
}
