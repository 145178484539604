export default function Footer({}) {
    const currentYear = new Date().getFullYear();
    return (
        <footer className="  mx-auto">
            <div className="mx-auto container w-full p-4 py-6 lg:py-8">
                <hr className="my-6 border-gray-200 sm:mx-auto lg:my-8"/>
                <div className="md:flex md:justify-between">
                    <div className="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-3">
                        <div>
                            <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">Company</h2>
                            <ul className="text-gray-500 dark:text-gray-400 font-medium">
                                <li className="mb-4">
                                    <a href="/blog" className="text-gray-500 hover:text-gray-900 ">
                                        Blog
                                    </a>
                                </li>
                                <li className="mb-4">
                                    <a href="/contact" className="text-gray-500 hover:text-gray-900">
                                        Contact
                                    </a>
                                </li>
                                <li>
                                    <a href={route('privacy')} className="text-gray-500 hover:text-gray-900">
                                        Privacy Policy
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">Resources</h2>
                            <ul className="text-gray-500 dark:text-gray-400 font-medium">
                                <li className="mb-4">
                                    <a href={route('landing.extract-hardcoded')}
                                       className="text-gray-500 hover:text-gray-900 ">
                                        Extract Hardcoded Subtitles
                                    </a>
                                </li>
                                <li className="mb-4">
                                    <a href={route('landing.generate-subtitles')}
                                       className="text-gray-500 hover:text-gray-900 ">
                                        Generate Subtitles From Videos
                                    </a>
                                </li>
                                <li className="mb-4">
                                    <a href={route('faqs')}
                                       className="text-gray-500 hover:text-gray-900 ">
                                        FAQs
                                    </a>
                                </li>
                                <li className="mb-4">
                                    <a href="https://chromewebstore.google.com/detail/subtitle-extractor/mifpkhfchoibfhkcpiecalegnngapioj"
                                       target="_blank"
                                       className="text-gray-500 hover:text-gray-900 ">
                                        Chrome Extension
                                    </a>
                                </li>

                            </ul>
                        </div>
                    </div>
                    <span className="text-sm text-gray-500 sm:text-center inline-block mt-6 md:mt-0 ">© {currentYear} <a
                        href={route('home')} className="hover:underline">Subtitle Extractor</a>. All Rights Reserved.
          </span>
                </div>

            </div>
        </footer>


    )
}
