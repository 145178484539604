import {PageProps} from "@/types";
import PageLayout from "@/Layouts/PageLayout";
import {Head, Link, usePage} from "@inertiajs/react";
import {faChartLine, faCreditCard, faFileInvoiceDollar} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";

export type ApiLayoutProps = {
    children: React.ReactNode
}
export default function ({children}: ApiLayoutProps) {
    const {auth: {user}} = usePage<PageProps>().props

    return (
        <>
            <Head title="Billing">
                <meta name="description" content="Billing and payment information."/>
            </Head>
            <PageLayout header={<h1 className="font-semibold text-xl text-gray-800 leading-tight">Billing</h1>}>
                <div className="container mx-auto lg:flex">
                    <aside id="sidebar"
                           className="w-full lg:w-1/5 px-2 lg:px-0 lg:ml-6 lg:border-r lg:pr-5 mt-3 lg:mt-10">
                        <ul className="flex lg:flex-col py-1 list-unstyled fw-normal small lg:overflow-visible overflow-x-auto px-2 lg:px-0 space-x-2 lg:space-x-0 lg:space-y-2">
                            <li className="flex-shrink-0">
                                <Link
                                    href={route('billing.credits')}
                                    className={`flex items-center p-2 text-base text-gray-900 rounded-lg hover:bg-gray-200 group ${route().current('billing.credits') ? 'bg-white' : ''} `}
                                >
                                    <FontAwesomeIcon
                                        icon={faCreditCard}
                                        className={route().current('billing.credits') ? 'text-blue-500' : 'text-gray-500'}
                                    />
                                    <span className="ml-3">Credits</span>
                                </Link>
                            </li>
                            <li className="flex-shrink-0">
                                <Link
                                    href={route('billing.usage')}
                                    className={`flex items-center p-2 text-base text-gray-900 rounded-lg hover:bg-gray-200 group ${route().current('billing.usage') ? (user ? 'bg-white' : 'bg-gray-100') : ''} `}
                                >
                                    <FontAwesomeIcon
                                        icon={faChartLine}
                                        className={route().current('billing.usage') ? 'text-blue-500' : 'text-gray-500'}
                                    />
                                    <span className="ml-3">Usage</span>
                                </Link>
                            </li>
                            <li className="flex-shrink-0">
                                <Link
                                    href={route('billing.invoice-settings')}
                                    className={`flex items-center p-2 text-base text-gray-900 rounded-lg hover:bg-gray-200 group ${route().current('billing.invoice-settings') ? 'bg-white' : ''} `}
                                >
                                    <FontAwesomeIcon
                                        icon={faFileInvoiceDollar}
                                        className={route().current('billing.invoice-settings') ? 'text-blue-500' : 'text-gray-500'}
                                    />
                                    <span className="ml-3">Invoicing</span>
                                </Link>
                            </li>
                        </ul>
                    </aside>
                    <hr className="lg:hidden mt-3"/>
                    <div className="min-h-[70vh] w-full lg:w-4/5 lg:flex-col px-4 lg:px-8 mt-4 lg:mt-10">
                        {children}
                    </div>
                </div>
            </PageLayout>
        </>
    )
}
