import AuthenticatedLayout from '@/Layouts/Auth/AuthenticatedLayout';
import {Head, router} from '@inertiajs/react';
import {ExtractorProps, JobDetails, PageProps, PersistedJob, UserSettings} from '@/types';
import ExtractionJobsTable from "@/Pages/Dashboard/Partials/ExtractionJobsTable";
import React, {useEffect, useState} from "react";
import toast from "react-hot-toast";
import UploadModal, {RemoteUpload} from "@/Pages/Dashboard/Upload/UploadModal";
import {ExtractionJobStatus, listenForExtractionJobUpdates, startJob} from "@/services/extractionJobManager";
import MediaFileInput from "@/Components/MediaFileInput";
import {bytesToSize} from "@/utils/textFormatting";
import moment from "moment";
import Credits from "@/Pages/Dashboard/Partials/Credits";
import {triggerDownload} from "@/utils/fileUtils";
import {AnalyticsEvent, sendEvent} from "@/services/analytics";
import RemoteUploadModal from "@/Pages/Dashboard/Partials/RemoteUploadModal";
import usePaginatedSource from "@/hooks/usePaginatedSource";
import axios from "axios";
import FeedbackModal from "@/Pages/Dashboard/Feedback/FeedbackModal";
import TranslateModal from "@/Pages/Dashboard/Translate/TranslateModal";
import {bulkDownloadSubtitles} from "@/services/subtitleService";
import {TranslationMethod} from "@/types/enums";
import EmptyInboxIcon from "@/Components/Icons/EmptyInboxIcon";

type DashboardPageProps = {
    totalJobsWithDeleted: number,
    totalJobs: number,
    extractorParams: ExtractorProps,
    csrf_token: string,
    creditBalance: number,
    userSettings: UserSettings
}

export default function Dashboard({
    auth,
    extractorParams,
    creditBalance,
    totalJobsWithDeleted,
    userSettings,
    totalJobs: initialTotalJobs,
}: PageProps<DashboardPageProps>) {
    const [isNewUser, setIsNewUser] = useState(!totalJobsWithDeleted);
    const [totalJobs, setTotalJobs] = useState(initialTotalJobs);
    const [selectedFiles, setSelectedFiles] = useState<File[]>([])
    const [remoteUpload, setRemoteUpload] = useState<RemoteUpload | null>(null)
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [showRemoteUploadModal, setShowRemoteUploadModal] = useState(false);
    const [showTranslationTip, setShowTranslationTip] = useState(false);
    const [feedbackJob, setFeedbackJob] = useState<PersistedJob | null>(null);
    const [bulkTranslateJobs, setBulkTranslateJobs] = useState<PersistedJob[]>([]);
    const paginatedJobs = usePaginatedSource<PersistedJob>('subtitle.list', 15)

    useEffect(() => {
        if (selectedFiles.length) {
            setShowUploadModal(true);
        }
    }, [selectedFiles]);

    useEffect(() => {
        const runningJobs = paginatedJobs.items.filter(job => ExtractionJobStatus.isRunning(job.status));
        const listeners = runningJobs.map(job => {
            let completed = false;
            return listenForExtractionJobUpdates({
                job_name: job.job_name!,
                method: job.method,
                video_duration: job.file_duration!,
                started_at: moment(job.created_at).valueOf(),
                on_update: (progress) => {
                    paginatedJobs.updateItems(
                        prev => prev.map(j => j.job_name === job.job_name ? {...j, progress} : j)
                    )
                    if (progress === 100 && !completed) {
                        completed = true;
                        handleJobComplete(job)
                    }
                },
                on_error: (err) => {
                    toast.error(err.message);
                    paginatedJobs.reloadItems({update: true});
                }
            })
        })
        return () => {
            listeners.forEach(el => el.disconnect());
        }
    }, [paginatedJobs.pagination.currentPage, paginatedJobs.pagination.total]);

    useEffect(() => {
        if (paginatedJobs.error) {
            toast.error(paginatedJobs.error);
        }
    }, [paginatedJobs.error]);

    useEffect(() => {
        if (paginatedJobs.initialized) {
            setTotalJobs(paginatedJobs.pagination.total)
        }
    }, [paginatedJobs.pagination.total, paginatedJobs.initialized]);

    const onFilesSelected = (files: File[]) => {
        const subtitlePattern = /\.(srt|vtt)$/i;
        const subtitles = files.filter(file => file.name.match(subtitlePattern));
        const videos = files.filter(file => !file.name.match(subtitlePattern));
        if (subtitles.length) {
            toast.promise(uploadSubtitles(subtitles), {
                loading: 'Uploading subtitles...',
                success: 'Subtitles uploaded successfully!',
                error: err => err.response?.data?.message || 'Failed to upload subtitles'
            })
        } else {
            setSelectedFiles(videos);
        }
    }

    const uploadSubtitles = (files: File[]) => {
        return axios.post(route('subtitle.upload'), {
            files: files
        }, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(response => {
            paginatedJobs.reloadItems({update: true});
        })
    }

    const handleJobComplete = (job: PersistedJob) => {
        toast('Extraction complete!', {icon: '🎉'});
        paginatedJobs.reloadItems({completed: job.id}).then(jobs => {
            const outputFileSize = jobs[0]?.output_file_size || 0; // this maybe incorrect if currentPage is not 1
            if (outputFileSize > 0) {
                if (isNewUser) {
                    setShowTranslationTip(true);
                }
                sendEvent(AnalyticsEvent.EXTRACTION_SUCCESS, {method: job.method});
                if (userSettings.downloadOnComplete) {
                    triggerDownload(route('job.download', job.id));
                }
            }
            setIsNewUser(false);
        })
    }

    const handleBulkTranslateComplete = async (jobIds: string[], language: string, method: TranslationMethod) => {
        const totalFailed = bulkTranslateJobs.length - jobIds.length;
        toast.success(`${jobIds.length} subtitles translated successfully! ${totalFailed ? `${totalFailed} failed.` : ''}`);
        bulkDownloadSubtitles(jobIds, language);
        setBulkTranslateJobs([]);
    }

    const refreshCredits = (): Promise<number> => {
        return new Promise((resolve, reject) => {
            router.reload({
                only: ['creditBalance', 'auth'],
                preserveState: true,
                preserveScroll: true,
                onSuccess: (page) => {
                    resolve(page.props.creditBalance as number)
                }
            })
        });
    }

    async function initJobs(
        uploads: JobDetails[]
    ): Promise<Record<string, Error>> {
        const promises = uploads.map(upload => {
            return startJob(upload)
                .then(result => ({status: 'resolved', id: upload.id, result, error: null}))
                .catch(error => ({status: 'rejected', id: upload.id, error}));
        });

        const results = await Promise.all(promises);
        const errors = results
            .filter(r => r.status === 'rejected')
            .reduce((acc, cur) => {
                return {...acc, [cur.id]: cur.error}
            }, {})

        const totalErrors = Object.keys(errors).length;
        if (totalErrors < uploads.length) {
            toast.success('Extraction started!');
            sendEvent(AnalyticsEvent.EXTRACTION, {
                method: uploads[0].method,
                batch_size: uploads.length - totalErrors
            });
            setShowUploadModal(false);
        }

        paginatedJobs.setPage(1, true);
        setSelectedFiles([]);
        return errors;
    }

    useEffect(() => {
        if (remoteUpload) {
            setShowRemoteUploadModal(false)
            setShowUploadModal(true)
        }
    }, [remoteUpload])

    return (
        <AuthenticatedLayout
            header={<h2 className="font-semibold text-xl text-gray-800 leading-tight">Dashboard</h2>}
        >
            <Head title="Dashboard"/>
            <FeedbackModal
                job={feedbackJob}
                onClose={() => setFeedbackJob(null)}
                onSubmitted={() => {
                    toast.success('Feedback submitted!')
                    setFeedbackJob(null)
                }}
            />
            <UploadModal
                extractAudioClientSide={userSettings.extractAudioClientSide}
                initialFiles={selectedFiles}
                initialUpload={remoteUpload}
                show={showUploadModal}
                multiple={!auth.user.is_trial}
                credits={creditBalance}
                onCreditRefresh={refreshCredits}
                extractorParams={extractorParams}
                onClose={() => {
                    setShowUploadModal(false);
                    setSelectedFiles([])
                    setRemoteUpload(null)
                }}
                submit={initJobs}
            />
            <TranslateModal
                isFreeTrial={auth.user.is_trial}
                show={bulkTranslateJobs.length > 0}
                translatedLanguages={[]}
                onTranslateComplete={handleBulkTranslateComplete}
                jobs={bulkTranslateJobs}
                onClose={(inProgressLanguage) => {
                    setBulkTranslateJobs([]);
                }}
            />
            <RemoteUploadModal
                show={showRemoteUploadModal}
                onClose={() => {
                    setShowRemoteUploadModal(false);
                    setRemoteUpload(null)
                }}
                onUploadComplete={upload => {
                    setRemoteUpload(upload)
                    toast.success('Video imported successfully!')
                }}
            />
            <div className="container mx-auto">
                <div className="flex flex-col lg:flex-row py-4 md:py-8">

                    <div className="lg:w-1/4 2xl:w-1/5 m-4">
                        <div className="p-4 py-6 bg-white border border-gray-200 rounded-lg shadow-sm sm:p-6">
                            <Credits
                                user={auth.user}
                                prices={extractorParams.pricing}
                                credits={creditBalance}
                            />
                        </div>
                    </div>


                    <div className="lg:w-3/4 2xl:w-4/5 m-4">
                        <div className=" pt-6 bg-white border border-gray-200 rounded-lg shadow-sm">
                            <div className="mb-4 px-6 lg:mb-0">
                                <h5 className="mb-2 text-xl font-bold text-gray-900">
                                    Recent Files
                                </h5>

                            </div>
                            <div className={!isNewUser ? 'py-4' : 'py-6'}>
                                <MediaFileInput
                                    canSelectUrl={!auth.user.is_trial}
                                    multiple={true}
                                    onSelectUrl={() => setShowRemoteUploadModal(true)}
                                    maxSize={extractorParams.maxFileSize}
                                    maxFiles={extractorParams.maxConcurrentJobs}
                                    onFileSizeExceeded={e => {
                                        toast.error(`File is too large. Max file size is ${bytesToSize(extractorParams.maxFileSize)}`);
                                    }}
                                    onFilesSelected={onFilesSelected}/>
                            </div>

                            {
                                totalJobs > 0 && <ExtractionJobsTable
                                    onBulkTranslate={(jobs) => setBulkTranslateJobs(jobs)}
                                    paginatedJobs={paginatedJobs}
                                    initialRows={totalJobs}
                                    onFeedback={job => setFeedbackJob(job)}
                                    showTranslateTip={showTranslationTip}
                                />
                            }
                            {
                                !totalJobs && <div>
                                    <hr className="my-12 bg-gray-900"/>
                                    <div className="text-center  px-10 mb-28 mt-38">
                                        <EmptyInboxIcon/>
                                        <p className="text-xl text-gray-400">You have no recent files</p>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>


            <div className="py-2"></div>
        </AuthenticatedLayout>
    );
}
