import {Head} from "@inertiajs/react";
import {PageProps} from "@/types";
import BillingPageLayout from "@/Pages/Billing/BillingPageLayout";
import InvoiceSettingsForm, {InvoiceSettings} from "@/Pages/Billing/Partials/InvoiceSettingsForm";

type InvoiceSettingsPageProps = {
    invoiceSettings: InvoiceSettings
}

export default function InvoiceSettingsPage({ auth, invoiceSettings, sessionFlash }: PageProps<InvoiceSettingsPageProps>) {
    return (
        <>
            <Head title="Invoice Settings" />
            <BillingPageLayout>
                <div className="flex flex-row justify-between items-center">
                    <h1 className="text-2xl">Invoice Settings</h1>
                </div>
                <div className="py-8">
                    <div className="max-w-7xl mx-auto space-y-6">
                        <div className="p-4 sm:p-8 bg-white shadow rounded-lg">
                            <InvoiceSettingsForm
                                settings={invoiceSettings}
                                className="max-w-xl"
                            />
                        </div>
                    </div>
                </div>
            </BillingPageLayout>
        </>
    );
}
