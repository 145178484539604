import {useForm} from "@inertiajs/react";
import InputLabel from "@/Components/InputLabel";
import TextInput from "@/Components/TextInput";
import InputError from "@/Components/InputError";
import {User} from "@/types";
import toast from "react-hot-toast";

export default function Form({user}: { user?: User }) {
    const {data, setData, post, processing, errors, recentlySuccessful} = useForm({
        email: user?.email || '',
        name: user?.name || '',
        message: '',
    })

    function handleSubmit(e: any) {
        e.preventDefault()
        post('/contact', {
            preserveScroll: true,
            onSuccess: () => {
                setData('message', '')
                toast.success('Message sent!')
            }
        })
    }


    return (
        <>
            <form onSubmit={handleSubmit} className="mt-6 space-y-6">
                <div>
                    <InputLabel htmlFor="name" value="Name"/>
                    <TextInput
                        id="name"
                        className="mt-1 block w-full"
                        value={data.name}
                        onChange={(e) => setData('name', e.target.value)}
                        required
                        autoComplete="name"
                    />

                    <InputError className="mt-2" message={errors.name}/>
                </div>

                <div>
                    <InputLabel htmlFor="email" value="Email"/>

                    <TextInput
                        id="name"
                        className="mt-1 block w-full"
                        value={data.email}
                        onChange={(e) => setData('email', e.target.value)}
                        required
                        autoComplete="name"
                    />

                    <InputError className="mt-2" message={errors.email}/>
                </div>

                <div>
                    <InputLabel htmlFor="message" value="Message"/>

                    <textarea
                        id="message"
                        rows={6}
                        className="block p-2.5 w-full text-sm text-gray-900 rounded-lg shadow-sm border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                        placeholder="Enter your message..."
                        value={data.message}
                        onChange={(e) => setData('message', e.target.value)}
                        required
                        autoComplete="Message"
                    />

                    <InputError className="mt-2" message={errors.message}/>
                </div>


                <div className="flex items-center gap-4">
                    <button type="submit"
                            disabled={processing || recentlySuccessful}
                            className="py-3 px-5 text-sm font-medium text-center text-white rounded-lg bg-blue-700 sm:w-fit hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Send
                        message
                    </button>
                </div>
            </form>
        </>
    )
}
